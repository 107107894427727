<template>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="tasks"
        item-key="name"
        sort-by="name"
        class="elevation-1 pt-1"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-container>
          <v-row justify="center">
            <v-btn small class="mr-2" @click="uncheckCheckboxes">Décocher toutes les tâches</v-btn>
            <dialog-create-task dispatcherOnSave="addTaskRoutine" :dataToModify="dataToModify" :toAddOnSave="{lift_id: liftId}" :fieldsToAdd="['category']" @closeCreationTask="dataToModify = null"></dialog-create-task>
          </v-row>
          <v-row justify="center" class="pt-2">
            <v-btn-toggle color="primary" dense v-model="categoryFilter">
              <v-btn value="Station motrice">Station motrice</v-btn>
              <v-btn value="Station retour">Station retour</v-btn>
            </v-btn-toggle>
          </v-row>
          </v-container>
          <v-divider class="mt-1"></v-divider>
        </template>
        <template v-slot:item.checkbox="props">
          <input type="checkbox" @change="checkboxItem(props.item.task_id, $event.target.checked)" v-model="props.item.checkbox" class="checkbox-round" />
        </template>
        <template v-slot:item.title="props">
          {{ props.item.title }}
          <dialog-infos-task :task="props.item"></dialog-infos-task>
        </template>
        <template v-slot:item.actionsTask="props">
          <v-btn icon>
            <v-icon
            color="blue"
            large
            @click="modifyTask(props.item)">
              mdi-pencil-circle
            </v-icon>
          </v-btn>
          <v-btn
          icon>
            <v-icon
            color="red"
            large
            @click="$store.dispatch('deleteTaskRoutine', {task_id: props.item.task_id, lift_id: props.item.lift_id})">
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
</template>

<script>
import DialogCreateTask from '@/components/DialogCreateTask'
import DialogInfosTask from '@/components/DialogInfosTask'
export default {
  name: "TasksLift",
  components: {
    DialogCreateTask,
    DialogInfosTask,
  },
  props: {
    liftId: Number
  },
  data () {
    return {
      headers: [
        {text: 'Effectuée ?', value: 'checkbox'},
        {text: 'Tâche', value: 'title'},
        { text: "Modifier tâche", value: 'actionsTask', sortable: false },
      ],
      dataToModify: null,
      dialogDescription: {},
      refreshKey: 0,
      categoryFilter: "Station motrice",
    }
  },
  computed: {
    tasks() {
      this.refreshKey
      let result = this.$store.state.liftsStore.listTasksRoutine.map(v => ({...v, checkbox: false}))
      if(localStorage.tasksRoutineChecked == undefined){
        localStorage.tasksRoutineChecked = JSON.stringify({})
      }
      else{
        const tasksRoutineCheckedParsed = JSON.parse(localStorage.tasksRoutineChecked)
        for(const prop in tasksRoutineCheckedParsed){
          for(const indexItem in result){
            let item = result[indexItem]
            if(item.task_id == prop){
              item['checkbox'] = tasksRoutineCheckedParsed[prop]
            }
          }
        }
      }
      result = result.filter(e => e.category == this.categoryFilter)
      //Adding timestamp to image src allow to prevent cache of image (when changed...)
      return result
    },
  },
  watch: {
    liftId(){
          this.$store.dispatch('updateListTasksRoutine', {'lift_id': this.liftId})
    }
  },
  methods: {
      uncheckCheckboxes(){
        localStorage.removeItem('tasksRoutineChecked')
        this.refreshKey++
      },
      checkboxItem(index, value){
        let stateBefore = JSON.parse(localStorage.tasksRoutineChecked)
        stateBefore[index] = value
        localStorage.tasksRoutineChecked = JSON.stringify(stateBefore)
      },
      modifyTask(task){
        this.dataToModify = task
      }
  },
  mounted(){
    this.$store.dispatch('updateListTasksRoutine', {'lift_id': this.liftId})
  }
}
</script>

<style scoped>
.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: gray;
}
</style>