<!-- 
frontend\src\modules\authentication\Login.vue
Author: Author : Andre Baldo (http://github.com/andrebaldo/) -->
<template>
  <v-card>
    <v-card-title primary-title>
      <h1>Se connecter</h1>
    </v-card-title>
    <v-card-text>
      <v-form rounded v-model="isLoginFormValid">
        <v-text-field
          name="email"
          label="Nom d'utilisateur/Email*"
          id="email"
          v-model="email"
          prepend-icon="mdi-account-circle"
          required
          :rules="[checkIsRequired(email)]"
          type="email"
        ></v-text-field>

        <v-text-field
          name="password"
          label="Mot de passe*"
          id="password"
          v-model="password"
          :type="getPasswordFieldType()"
          prepend-icon="mdi-lock"
          :append-icon="getShowPasswordApendIcon()"
          @click:append="toggleShowPassword()"
          :rules="[checkIsRequired(password)]"
          required
        ></v-text-field>

        <v-checkbox
          v-model="rememberMe"
          label="Se souvenir de moi"
        ></v-checkbox>

        <div class="text-center">
          <v-progress-circular indeterminate color="primary" v-if="isProcessing"></v-progress-circular>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        elevation="9"
        :disabled="!isLoginFormValid || isProcessing"
        @click="authenticateUserAndSetToken({email:email, password:password, rememberMe:rememberMe})"
      >Connection</v-btn>
    </v-card-actions>
        <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        x-small
        elevation="9"
        :disabled="isProcessing"
        @click="$store.dispatch('loginForfait', rememberMe)"
      >Connection par forfait</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Login",
  data: function() {
    return {
      rememberMe: false,
      showPassword: false,
      isLoginFormValid: false,
      email: "",
      password: "",
      firstname: "",
      surname: "",
    };
  },
  computed: {
    ...mapGetters([
      "isProcessing"
    ]),
  },
  methods: {
    getPasswordFieldType: function() {
      if (this.showPassword) {
        return "text";
      } else {
        return "password";
      }
    },
    toggleShowPassword: function() {
      this.showPassword = !this.showPassword;
    },
    getShowPasswordApendIcon: function() {
      if (this.showPassword) {
        return "mdi-eye";
      } else {
        return "mdi-eye-off";
      }
    },
    checkIsRequired: function(value, errorMessage) {
      if (!errorMessage) {
        errorMessage = "Ce champ est nécessaire";
      }
      return !!value || errorMessage;
    },
    ...mapActions(["authenticateUserAndSetToken"]),
  },
}
</script>