<template>
  <v-data-table
  :headers="headers"
  :items="downloads"
  :options="{sortBy: ['date','sectorName']}"
  :items-per-page="15"
  :search="search"
  :loading="isLoading"
  :custom-sort="customSort"
  :multi-sort="true"
  loading-text="Chargement... Merci de patienter"
  :footer-props="{
  'items-per-page-text':'Nombre de lignes par page',
  'items-per-page-all-text':'Toutes'
  }"
  class="elevation-1"
  mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          class="m-0 p-0"
        ></v-text-field>
      </v-card-title>
    </template>
    <template v-slot:item.download="props">
        <div class="d-flex align-center">
            <v-btn
            class="mr-5"
            @click="downloadPdf(props.item['date'], props.item['sectorValue'])"
            >TELECHARGER</v-btn>
        </div>
    </template>
    <template v-slot:item.sign="props">
        <div class="d-flex align-center">
            <v-checkbox
              v-model="props.item.sign"
              @change="signPdf($event, props.item['date'], props.item['sectorValue'])"
              :disabled=isSignable
            ></v-checkbox>
        </div>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "TableDownload",
    data () {
      return {
        search: ''
      }        
    },
    computed: {
      downloads (){
        return this.$store.state.slopesStore.listDownloads;
      },
      isLoading(){
        return this.$store.state.slopesStore.listDownloadsIsLoading;
      },
      isSignable (){
        if(this.$store.state.authStore.isResponsablePistes){
          return false;
        } else{
          return true;
        }
      },
      headers (){
        var headers = [
          { text: 'Date', value: 'date'},
          { text: 'Secteur', value: 'sectorName', sortable: true },
          { text: 'Télécharger', value: 'download', sortable: false },
          { text: 'Signer la fiche (chef seulement)?', value: 'sign', sortable: false}
        ];
        return headers;
      }
    },
    watch: {
    },
    methods: {
        downloadPdf(date, sector){
          const payload = {'date':date, 'sector': sector};
          this.$store.dispatch("downloadPdf", payload);
        },
        signPdf(toSign, date, sector){
          const payload = {'date':date, 'sector': sector, 'toSign': toSign};
          this.$store.dispatch("signPdf", payload);
        },
        customSort(items, index, isDesc) {
          items.sort(function(a,b){
            let result = 0
            for(let i = 0; i < index.length; i++){
              let x = b
              let y = a
              if(isDesc[i]) {
                x = a
                y = b
              }
              if(index[i] == 'date') {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                const [dayx, monthx, yearx] = x.date.split('/');
                const [dayy, monthy, yeary] = y.date.split('/');
                result = new Date(+yearx, +monthx - 1, +dayx) - new Date(+yeary, +monthy - 1, +dayy)
              } else {
                result = x[index[i]].localeCompare(y[index[i]])
              }
              if (result != 0) {
                break
              }
            }
            return result;
          });
          return items;
        }
    },
    mounted() {
      this.$store.dispatch("updateListDownloads");
    }
  }


</script>