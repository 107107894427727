<template>
    <div>
      <v-container>
        <v-form ref="formSpecifyRecord" v-model="isSpecifyRecordFormValid">
          <v-row class="mt-2">
            <v-col cols="6">
              <v-autocomplete
                v-model="lift"
                :items="lift_list"
                item-text="lift_name"
                item-value="lift_id"
                label="Remontée"
                prepend-inner-icon="mdi-gondola"
                outlined
                :rules="[v => checkIsRequired(v)]"
                hide-details="auto"
                @change="loadRecordStored">
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menu_date_picker"
                :close-on-content-click="false"
                :nudge-left="$vuetify.breakpoint.smAndDown ? 200 : 0"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => checkIsRequired(v)]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  locale="fr"
                  :first-day-of-week="1"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  @input="menu_date_picker = false; loadRecordStored()"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row v-if='displayRecord'>
          <v-col>
            <v-dialog
              v-model="dialog_tasks_routine"
              max-width="900px"
            > 
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                x-large
                color="primary"
                v-bind="attrs"
                v-on="on"
                :class="$vuetify.breakpoint.smAndUp ? 'mr-10' : 'mb-2'">Tâches de routine</v-btn>
              </template>
              <v-card dense>
                <v-card-title>
                  <span class="text-h5">Tâches de routine</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialog_tasks_routine = false" >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <tasks-lift :liftId.sync="$store.state.liftsStore.liftRecordProps['lift_id']"></tasks-lift>
                </v-card-text>
              </v-card>
            </v-dialog>
            <br v-if="$vuetify.breakpoint.xs">
            <dialog-declare-breakout v-if="'lift_usage_id' in $store.state.liftsStore.liftRecord && !disabled_record"></dialog-declare-breakout>
          </v-col> 
        </v-row>

        <v-row v-if='displayRecord'>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-container v-if='displayRecord'>
          <v-form ref="coreRecord">
            <v-row>
              <v-col cols="12" sm="2" class="text-center my-auto">
                <span class="font-weight-bold">Météo ouverture</span>
              </v-col>
              <v-col cols="12" sm="6" class="mx-auto my-auto">
                <v-btn-toggle v-model="weather_opening" active-class="background-color: blue lighten-3">
                  <v-btn
                    v-for="(weather,index) in weather_list"
                    :key="'opening.'+index"
                    :value="weather.label"
                    :disabled="disabled_record"
                  >
                    <span class="hidden-md-and-down">{{ weather.label }}</span>
                    <v-icon :right="$vuetify.breakpoint.lgAndUp">{{ weather.icon }}</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="8" sm="4" class="my-auto mx-auto">
                <v-text-field
                  centered label="Vent ouverture (m/s)"
                  v-model="wind_opening"
                  :rules="[v => checkIsNumeric(v)]"
                  :disabled="disabled_record"
                  prepend-inner-icon="mdi-weather-windy"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" sm="2" class="text-center my-auto">
                <span class="font-weight-bold">Météo après-midi</span>
              </v-col>
              <v-col cols="12" sm="6" class="mx-auto my-auto">
                <v-btn-toggle v-model="weather_closing" active-class="background-color: blue lighten-3">
                  <v-btn
                    v-for="(weather,index) in weather_list"
                    :key="'closing.'+index"
                    :value="weather.label"
                    :disabled="disabled_record"
                  >
                  <span class="hidden-md-and-down">{{ weather.label }}</span>
                    <v-icon :right="$vuetify.breakpoint.lgAndUp">{{ weather.icon }}</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="8" sm="4" class="my-auto mx-auto">
                <v-text-field
                  centered label="Vent après-midi (m/s)"
                  v-model="wind_closing"
                  :rules="[v => checkIsNumeric(v)]"
                  :disabled="disabled_record"
                  prepend-inner-icon="mdi-weather-windy"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Rôle</th>
                          <th></th>
                          <th class="text-center">Agent</th>
                          <th></th>
                          <th class="text-center">Signature</th>
                          <th></th>
                          <th class="text-center">Début pause déjeuner</th>
                          <th></th>
                          <th class="text-center">Fin pause déjeuner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in roles_list" :key="item.role">
                          <td class="text-left">
                              <v-icon left> 
                                {{ item.icon }}
                              </v-icon>
                              {{ item.role }}
                          </td>
                          <td><v-divider vertical></v-divider></td>
                          <td><v-autocomplete
                            class="my-1"
                            v-model="item.agent"
                            :items="listRMPersons"
                            item-text="name"
                            item-value="id"
                            @change="updateValueRecord(item.roleFormatted, $event)"
                            style="min-width: 130px;"
                            :disabled="disabled_record"
                            hide-details="auto"
                            dense>
                              <template v-slot:append-outer>
                                <v-btn
                                  :class="classAgent(item.agent)"
                                  @click="updateValueRecord(item.roleFormatted, $store.state.authStore.dataUser['userId'])"
                                  small
                                >MOI</v-btn>
                              </template>
                            </v-autocomplete>
                          </td>
                          <td><v-divider vertical></v-divider></td>
                          <td><v-checkbox
                            class="ma-0 pa-0"
                            v-model="item.signature"
                            :disabled="item.agent != $store.state.authStore.dataUser['userId']"
                            @change="updateValueRecord(item.roleFormatted+'_signed', $event)"
                            hide-details="auto"
                            dense></v-checkbox></td>
                          <td><v-divider vertical></v-divider></td>
                          <td><v-text-field
                            class="ma-0 pa-0"
                            v-model="item.start_break"
                            value="--:--"
                            type="time"
                            @change="updateValueRecord(item.roleFormatted+'_break_time_start', $event)"
                            :disabled="disabled_record"
                            hide-details="auto"
                            dense></v-text-field></td>
                          <td><v-divider vertical></v-divider></td>
                          <td><v-text-field
                            class="ma-0 pa-0"
                            v-model="item.end_break"
                            value="--:--"
                            type="time"
                            @change="updateValueRecord(item.roleFormatted+'_break_time_end', $event)"
                            :disabled="disabled_record"
                            hide-details="auto"
                            dense></v-text-field></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-card height="100%">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="2" class="my-auto">
                        <v-icon color="green">mdi-timer-lock-open-outline</v-icon>
                      </v-col>
                      <v-col class="my-auto">
                        <span style="color: green" class="font-weight-bold">Heure d'ouverture</span>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          color="green"
                          v-model="opening_time"
                          :disabled="disabled_record"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card height="100%">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="2" class="my-auto">
                        <v-icon color="red">mdi-timer-lock-outline</v-icon>
                      </v-col>
                      <v-col class="my-auto">
                        <span style="color: red" class="font-weight-bold">Heure de fermeture</span>
                      </v-col>
                      <v-col>
                        <v-text-field 
                          dense
                          color="red"
                          v-model="closing_time"
                          :disabled="disabled_record"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="'lift_usage_id' in $store.state.liftsStore.liftRecord">
              <v-col>
                <v-expansion-panels focusable accordion v-model="panelTests" style="z-index: 0;">
                  <v-expansion-panel
                    v-for="(checks, category, index) in check_list"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      <v-row class="d-flex justify-space-between">
                        <!--<v-icon left>{{ category }}</v-icon>-->
                        <div cols="11">
                          <span>{{ category }}</span>
                        </div>
                        <div class="mr-3" v-if="checks['isAllTestsDone']" cols="1">
                          <v-icon color="success">mdi-check-circle</v-icon>
                        </div>
                        <div class="mr-3" v-else>
                          <span class="red--text font-weight-bold">{{ checks["numberTotalTestsDone"] }}/{{ checks["numberTotalTests"] }} </span>
                          <v-icon color="error">mdi-close-circle</v-icon>
                        </div>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-autocomplete
                        class="mt-5"
                        outlined
                        v-if="category == 'Ligne'"
                        label="Nom vérificateur"
                        v-model="ligne_test_person"
                        :items="listRMPersons"
                        :disabled="disabled_record"
                        item-text="name"
                        item-value="id"
                        dense
                      ></v-autocomplete>
                      <v-autocomplete
                        class="mt-5"
                        outlined
                        v-if="category == 'Station retour'"
                        label="Nom vérificateur"
                        v-model="station_motrice_test_person"
                        :items="listRMPersons"
                        :disabled="disabled_record"
                        item-text="name"
                        item-value="id"
                        dense
                      ></v-autocomplete>
                      <v-text-field
                        class="mt-5"
                        outlined
                        v-if="category == 'Station de tension'"
                        dense
                        v-model="cote_lorry"
                        label="Cote du lorry"
                        :rules="[v => checkIsNumeric(v)]"
                        :disabled="disabled_record"
                      ></v-text-field>
                      <v-text-field
                        class="mt-5"
                        outlined
                        v-if="category == 'Tension hydraulique'"
                        dense
                        v-model="pression_verin"
                        label="Pression hydraulique"
                        :rules="[v => checkIsNumeric(v)]"
                        :disabled="disabled_record"
                      ></v-text-field>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :style="{ width: '70%' }" style="padding: 0 5px" class="text-left">Essai</th>
                              <th :style="{ width: '10%' }" style="padding: 0 5px" class="text-center">A l'arrêt</th>
                              <th :style="{ width: '10%' }" style="padding: 0 5px" class="text-center">A vide</th>
                              <th :style="{ width: '10%' }" style="padding: 0 5px" class="text-center">En exploitation</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="test in checks['tests']"
                              :key="test.test_id"
                            >
                              <td :style="{ width: '70%' }" style="padding: 0 5px" class="text-left">{{ test.test_name }}</td>
                              <td :style="{ width: '10%' }" style="padding: 0 5px">
                                <v-checkbox dense class="d-inline-flex" v-if="test.verification_on_stop" :disabled="disabled_record || !((is_conducteur || is_conducteur_remplacant) || ((is_vigie || is_vigie_remplacant) && categories_allowed_vigie.includes(category)))" v-model="test['value_test_on_stop']" @change="$store.dispatch('modifyTestLiftRecord', {lift_usage_id: $store.state.liftsStore.liftRecord['lift_usage_id'], test_id: test.test_id, value_test_on_stop: $event})"></v-checkbox>
                              </td>
                              <td :style="{ width: '10%' }" style="padding: 0 5px">
                                <v-checkbox dense class="d-inline-flex" v-if="test.verification_empty" :disabled="disabled_record || !((is_conducteur || is_conducteur_remplacant) || ((is_vigie || is_vigie_remplacant) && categories_allowed_vigie.includes(category)))" v-model="test['value_test_empty']" @change="$store.dispatch('modifyTestLiftRecord', {lift_usage_id: $store.state.liftsStore.liftRecord['lift_usage_id'], test_id: test.test_id, value_test_empty: $event})"></v-checkbox>
                              </td>
                              <td :style="{ width: '10%' }" style="padding: 0 5px"> 
                                <v-checkbox dense class="d-inline-flex" v-if="test.verification_in_use" :disabled="disabled_record || !((is_conducteur || is_conducteur_remplacant) || ((is_vigie || is_vigie_remplacant) && categories_allowed_vigie.includes(category)))" v-model="test['value_test_in_use']" @change="$store.dispatch('modifyTestLiftRecord', {lift_usage_id: $store.state.liftsStore.liftRecord['lift_usage_id'], test_id: test.test_id, value_test_in_use: $event})"></v-checkbox>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="4" class="my-auto">
                    <span class="font-weight-bold">Numéro du dernier siège annoncé</span>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        centered label="Numéro"
                        v-model="nb_last_chair"
                        :rules="[v => checkIsInteger(v)]"
                        :disabled="disabled_record"
                        hide-details="auto"
                        outlined
                        prepend-inner-icon="mdi-close-network-outline"
                      >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="4" class="my-auto">
                    <span class="font-weight-bold">Compteur horaire fin de journée</span>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                        centered label="Nombre heures"
                        v-model="nb_hours"
                        :rules="[v => checkIsNumeric(v)]"
                        :disabled="disabled_record"
                        hide-details="auto"
                        outlined
                        prepend-inner-icon="mdi-clipboard-text-clock-outline"
                      >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="4" class="my-auto">
                    <span class="font-weight-bold">Compteur passage fin de journée</span>
                  </v-col>
                  <v-col cols="8">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                            centered label="Montée"
                            v-model="count_up"
                            :rules="[v => checkIsInteger(v)]"
                            :disabled="disabled_record"
                            prepend-inner-icon="mdi-arrow-top-right-bold-outline"
                            outlined
                            hide-details="auto"
                          >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                            centered label="Descente"
                            v-model="count_down"
                            :rules="[v => checkIsInteger(v)]"
                            :disabled="disabled_record"
                            prepend-inner-icon="mdi-arrow-bottom-right-bold-outline"
                            outlined
                            hide-details="auto"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                label="Commentaire"
                v-model="comment"
                :disabled="disabled_record"
                outlined
                hide-details="auto">
                </v-textarea>
              </v-col>
              <v-col cols="12" md="6" v-if="'lift_usage_id' in $store.state.liftsStore.liftRecord">
                <v-data-table
                  :headers="headersListTasksAssociated"
                  :items="listTasksAssociated"
                  class="pt-1"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <dialog-create-task v-if="!disabled_record" dispatcherOnSave= "addTaskMaintenance" :toAddOnSave="{lift_id: $store.state.liftsStore.liftRecordProps['lift_id'], user_created: $store.state.authStore.dataUser['userId'], record_created: $store.state.liftsStore.liftRecord['lift_usage_id']}"></dialog-create-task>
                    <v-divider class="mt-1"></v-divider>
                  </template>
                  <template v-slot:item.checkbox="props">
                    <v-btn
                      icon
                      large
                      :color="props.item.is_done ? 'success' : 'gray'"
                    >
                      <v-icon >
                        mdi-check-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.infos="props">
                    <dialog-infos-task :task="props.item"></dialog-infos-task>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-row v-if='displayRecord'>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row v-if='displayRecord'>
          <v-col cols="12">
            <v-card>
              <v-card-title class="justify-center">
                <span class="font-weight-bold text-title-1">Signatures du registre</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col v-for="(role,index) in signatures_mapping" :key="index" cols="6" md="3">
                    <v-card height="100%">
                      <v-card-title class="font-weight-bold text-subtitle-2 justify-center">{{role.label}}</v-card-title>
                      <v-card-text class="text-center">
                        <v-chip
                          :color="is_signed(role.signature) ? 'green' : 'red'"
                          outlined
                          class="my-auto mx-auto"
                          >
                          <div v-if='is_signed(role.signature)'>
                            <span>Signé</span>
                            <v-icon right>
                              mdi-check-circle
                            </v-icon>
                          </div>
                          <div v-else>
                            <span>Non signé</span>
                            <v-icon right>
                              mdi-close-circle
                            </v-icon>
                          </div>
                        </v-chip>
                        <br>
                        <div v-if="final_signature_role.includes(role.signature)">
                          <v-dialog
                            v-model="dialog_final_signature[role.signature]"
                            max-width="900px"
                          > 
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                              v-bind="attrs"
                              v-on="on"
                              :color="check_signed(role.signature) ? 'red' : 'green'" 
                              dark
                              class="mt-2"
                              >
                              <div v-if="!check_signed(role.signature)">
                                <span>Signer</span>
                                <v-icon right>mdi-file-sign</v-icon>
                              </div>
                              <div v-else>
                                <span>Annuler</span>
                                <v-icon right>mdi-file-sign</v-icon>
                              </div>
                              </v-btn>
                            </template>
                            <v-card dense>
                              <v-card-title>
                                <span class="text-h5">Signature de la fiche</span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="dialog_final_signature[role.signature] = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-card-text v-if="role.closeRecord">
                                <span v-if="check_signed(role.signature) == false" class="font-weight-bold">La signature de cette fiche n'est à effectuer qu'une fois le registre complété.<br>
                                Cliquer sur le bouton "Signer" bloquera toute prochaine modification du registre</span>
                                <span v-if="check_signed(role.signature) == true" class="font-weight-bold">Vous pouvez choisir d'enlever votre signature si le registre nécessite une nouvelle modification</span>
                              </v-card-text>
                              <v-card-text v-else>
                                <span v-if="check_signed(role.signature) == false" class="font-weight-bold">La signature de cette fiche par vous ne bloquera pas la modification de la fiche.<br>
                                Ainsi, d'autres modifications pourront être effectuées même après signature de cette case.</span>
                                <span v-else class="font-weight-bold">Vous pouvez choisir d'enlever votre signature.</span>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="close(role.signature)"
                                >
                                <span v-if="check_signed(role.signature) == false">Annuler</span>
                                <span v-else>Laisser signature</span>
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="final_sign(role.signature)"
                                >
                                <span v-if="check_signed(role.signature) == false">Signer</span>
                                <span v-else>Enlever</span>
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </div>
</template>

<script>
import DialogDeclareBreakout from '@/components/DialogDeclareBreakout'
import DialogCreateTask from '@/components/DialogCreateTask'
import TasksLift from '@/components/TasksLift'
import DialogInfosTask from '@/components/DialogInfosTask'
export default {
  name: "LiftRecord",
  components:{
    DialogDeclareBreakout,
    DialogCreateTask,
    TasksLift,
    DialogInfosTask,
  },
  data() {
    return {
      panelTests: 0,
      dialog_tasks_routine: false,
      dialog_final_signature: {},
      displayRecord: this.$store.state.liftsStore.liftRecordProps['date'] && this.$store.state.liftsStore.liftRecordProps['lift_id'],
      isSpecifyRecordFormValid: false,
      weather_list:[
        {label: "Soleil",icon: "mdi-weather-sunny"},
        {label: "Nuages",icon: "mdi-weather-cloudy"},
        {label: "Brouillard",icon: "mdi-weather-fog"},
        {label: "Pluie",icon: "mdi-weather-rainy"},
        {label: "Neige",icon: "mdi-weather-snowy-heavy"}
      ],
      menu_date_picker: false,
      newMaintenanceTask: '',
      headersListTasksAssociated: [
          {text: 'Effectuée ?', value: 'checkbox'},
          {text: 'Tâche', value: 'title', cellClass: 'pr-0', class: 'pr-0'},
          {text: '', value: 'infos', cellClass: 'px-0', class: 'px-0', sortable: false},
      ],
      dialogDescription: {},
      signatures_mapping:[
        {label: "Conducteur",signature: "final_signature_conducteur", closeRecord: true},
        {label: "Chef d'équipe",signature: "final_signature_chef_equipe", closeRecord: false},
        {label: "Chef d'exploitation",signature: "final_signature_chef_exploitation", closeRecord: true},
        {label: "STRMTG",signature: "final_signature_strmtg", closeRecord: true}
      ],
      categories_allowed_vigie:["Véhicules","Groupe de sécurité","Station de tension","Tension hydraulique","Station retour"],
    }
  },
  computed: {
    disabled_record(){
      const is_today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) == this.$store.state.liftsStore.liftRecordProps['date']
      const is_already_signed = this.signatures_mapping.filter(el => el.closeRecord == true).some(el => this.$store.state.liftsStore.liftRecord[el.signature])
      return !is_today || is_already_signed
    },
    check_list(){
      let result = {}
      for(const test of this.$store.state.liftsStore.listRecordTests){
        let testFinal = null
        if(test.test_id in this.$store.state.liftsStore.liftRecord['tests']) {
          testFinal = {... test, ...this.$store.state.liftsStore.liftRecord['tests'][test.test_id]}
        } else {
          testFinal = {... test, ...{'value_test_on_stop': false, 'value_test_empty': false, 'value_test_in_use': false,}}
        } 
        if(test['test_category'] in result){
          result[test['test_category']]["tests"].push(testFinal)
        } else {
          result[test['test_category']] = {}
          result[test['test_category']]["tests"] = [testFinal]
        }
      }
      for(const category in result){
        [result[category]["numberTotalTests"], result[category]["numberTotalTestsDone"], result[category]["isAllTestsDone"]] = this.allTestsDone(result[category]["tests"])
      }
      return result
    },
    check_list_categories(){
      return [...new Set(this.check_list.map(({ test_category }) => test_category))]
    },
    is_conducteur(){
      return (Number(this.$store.state.liftsStore.liftRecord['conducteur']) == Number(this.$store.state.authStore.dataUser['userId']))
    },
    is_vigie(){
      return (Number(this.$store.state.liftsStore.liftRecord['vigie']) == Number(this.$store.state.authStore.dataUser['userId']))
    },
    is_conducteur_remplacant(){
      return (Number(this.$store.state.liftsStore.liftRecord['conducteur_remplacant']) == Number(this.$store.state.authStore.dataUser['userId']))
    },
    is_vigie_remplacant(){
      return (Number(this.$store.state.liftsStore.liftRecord['vigie_remplacant']) == Number(this.$store.state.authStore.dataUser['userId']))
    },
    final_signature_role(){
      let result = []
      if(Number(this.$store.state.liftsStore.liftRecord['conducteur']) == Number(this.$store.state.authStore.dataUser['userId'])){
        result.push('final_signature_conducteur')
      }
      if(this.$store.state.authStore.isResponsableRM){
        result.push('final_signature_chef_exploitation')
      }
      if(this.$store.state.authStore.dataUser.autorisation.some(el => el == 'chef_equipe_rm')){
        result.push('final_signature_chef_equipe')
      }
      if(this.$store.state.authStore.dataUser.autorisation.some(el => el == 'strmtg')){
        result.push('final_signature_strmtg')
      }
      return result
    },
    lift_list(){
      return this.$store.state.liftsStore.liftsList
    },
    listTasksAssociated(){
      return this.$store.state.liftsStore.listTasksMaintenance
    },
    computedDateFormatted () {
      const [year, month, day] = this.date.split('-')
      const month_list = ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre']
      return (day+' '+month_list[month-1]+' '+year)
    },
    lift: {
      get() { return this.$store.state.liftsStore.liftRecordProps['lift_id'] },
      set(value) { this.$store.dispatch('callSetter', {'setterName': 'setLiftIdRecordProps', 'value': value}) }
    },
    date: {
      get() { return this.$store.state.liftsStore.liftRecordProps['date'] },
      set(value) { this.$store.dispatch('callSetter', {'setterName': 'setLiftDateRecordProps', 'value': value}) }
    },
    weather_opening: {
      get() { return this.$store.state.liftsStore.liftRecord['weather_opening'] },
      set(value) { this.updateValueRecord('weather_opening', value) }
    },
    weather_closing: {
      get() { return this.$store.state.liftsStore.liftRecord['weather_closing'] },
      set(value) { this.updateValueRecord('weather_closing', value) }
    },
    wind_opening: {
      get() { return this.$store.state.liftsStore.liftRecord['wind_opening'] },
      set(value) { if(((value.replace(',', '.') >= 0) && value.replace(',', '.')[value.length - 1] != '.') || (value == null)) {this.updateValueRecord('wind_opening', value.replace(',', '.'))} }
    },
    wind_closing: {
      get() { return this.$store.state.liftsStore.liftRecord['wind_closing'] },
      set(value) { if(((value.replace(',', '.') >= 0) && value.replace(',', '.')[value.length - 1] != '.') || (value == null)) {this.updateValueRecord('wind_closing', value.replace(',', '.'))} }
    },
    station_motrice_test_person: {
      get() { return String(this.$store.state.liftsStore.liftRecord['station_motrice_test_person']) },
      set(value) { this.updateValueRecord('station_motrice_test_person', value) }
    },
    ligne_test_person: {
      get() { return String(this.$store.state.liftsStore.liftRecord['ligne_test_person']) },
      set(value) { this.updateValueRecord('ligne_test_person', value) }
    },
    cote_lorry: {
      get() { return this.$store.state.liftsStore.liftRecord['cote_lorry'] },
      set(value) { if(((value.replace(',', '.') >= 0) && value.replace(',', '.')[value.length - 1] != '.') || (value == null)) {this.updateValueRecord('cote_lorry', value.replace(',', '.'))} }
    },
    pression_verin: {
      get() { return this.$store.state.liftsStore.liftRecord['pression_verin'] },
      set(value) { if(((value.replace(',', '.') >= 0) && value.replace(',', '.')[value.length - 1] != '.') || (value == null)) {this.updateValueRecord('pression_verin', value.replace(',', '.'))} }
    },
    opening_time: {
      get() { return this.$store.state.liftsStore.liftRecord['public_opening_time'] },
      set(value) { this.updateValueRecord('public_opening_time', value) }
    },
    closing_time: {
      get() { return this.$store.state.liftsStore.liftRecord['public_closing_time'] },
      set(value) { this.updateValueRecord('public_closing_time', value) }
    },
    count_down: {
      get() { return this.$store.state.liftsStore.liftRecord['people_count_end_day_descente'] },
      set(value) { if((value >= 0) || (value == null)) {this.updateValueRecord('people_count_end_day_descente', value)} }
    },
    count_up: {
      get() { return this.$store.state.liftsStore.liftRecord['people_count_end_day_montee'] },
      set(value) { if((value >= 0) || (value == null)) {this.updateValueRecord('people_count_end_day_montee', value)} }
    },
    nb_last_chair: {
      get() { return this.$store.state.liftsStore.liftRecord['nb_last_chair'] },
      set(value) { if((value >= 0) || (value == null)) {this.updateValueRecord('nb_last_chair', value)} }
    },
    nb_hours: {
      get() { return this.$store.state.liftsStore.liftRecord['hours_count_end_day'] },
      set(value) { if(((value.replace(',', '.') >= 0) && value.replace(',', '.')[value.length - 1] != '.') || (value == null)) {this.updateValueRecord('hours_count_end_day', value.replace(',', '.'))} }
    },
    comment: {
      get() { return this.$store.state.liftsStore.liftRecord['comment'] },
      set(value) { this.updateValueRecord('comment', value) }
    },
    roles_list(){
      const liftRecord = this.$store.state.liftsStore.liftRecord
      return [
        {role: 'Conducteur', roleFormatted: 'conducteur', icon: "mdi-ship-wheel",agent: String(liftRecord['conducteur']),signature: liftRecord['conducteur_signed'],start_break: liftRecord['conducteur_break_time_start'],end_break: liftRecord['conducteur_break_time_end']},
        {role: 'Vigie', roleFormatted: 'vigie',icon: "mdi-binoculars",agent: String(liftRecord['vigie']),signature: liftRecord['vigie_signed'],start_break: liftRecord['vigie_break_time_start'],end_break: liftRecord['vigie_break_time_end']},
        {role: 'Contrôleur', roleFormatted: 'controleur',icon: "mdi-check-all",agent: String(liftRecord['controleur']),signature: liftRecord['controleur_signed'],start_break: liftRecord['controleur_break_time_start'],end_break:liftRecord['controleur_break_time_end']},
        {role: 'Remplaçant conducteur', roleFormatted: 'conducteur_remplacant',icon: "mdi-ship-wheel",agent: String(liftRecord['conducteur_remplacant']),signature: liftRecord['conducteur_remplacant_signed'],start_break: liftRecord['conducteur_remplacant_break_time_start'],end_break: liftRecord['conducteur_remplacant_break_time_end']},
        {role: 'Remplaçant vigie', roleFormatted: 'vigie_remplacant',icon: "mdi-binoculars",agent: String(liftRecord['vigie_remplacant']),signature: liftRecord['vigie_remplacant_signed'],start_break: liftRecord['vigie_remplacant_break_time_start'],end_break: liftRecord['vigie_remplacant_break_time_end']},
        {role: 'Remplaçant contrôleur', roleFormatted: 'controleur_remplacant',icon: "mdi-check-all",agent: String(liftRecord['controleur_remplacant']),signature: liftRecord['controleur_remplacant_signed'],start_break: liftRecord['controleur_remplacant_break_time_start'],end_break: liftRecord['controleur_remplacant_break_time_end']}
      ]
    },
    listRMPersons(){
      var arr = this.$store.state.liftsStore.listRMPersons;
      arr.unshift({name:"", id: null, pathAvatar:""});
      return arr;
    },
  },
  methods: {
    classAgent(agent){
      if(agent == this.$store.state.authStore.dataUser["userId"]){
        return "light-blue lighten-2"
      } else {
        return "light-blue lighten-5"
      }
    },
    check_signed(role){
      if (this.$store.state.liftsStore.liftRecord[role]){return true}
      return false
    },
    is_signed(signature){
      if (signature in this.$store.state.liftsStore.liftRecord){return this.$store.state.liftsStore.liftRecord[signature]}
      return false
    },
    updateValueRecord(variableName, value){
      let updatingObject = {}
      updatingObject[variableName] = value
      this.$store.commit('setLiftRecordElement', updatingObject)
      this.$store.dispatch('modifyLiftRecord', updatingObject)
    },
    checkIsRequired(value, errorMessage) {
      if (errorMessage == null) {
          errorMessage = "Ce champ est nécessaire";
      }
      return !!value || errorMessage;
    },
    checkIsNumeric(value, errorMessage) {
      if (errorMessage == null) {
        errorMessage = 'La valeur doit être un nombre. Ex: 15'
      }
      if (value == null){
        return true
      } else if(value.replace(',', '.') >= 0) {
        return true
      } else {
        return errorMessage
      } 
    },
    checkIsInteger(value, errorMessage) {
      if (errorMessage == null) {
        errorMessage = 'La valeur doit être un nombre. Ex: 15'
      }
      if (value == null || value >= 0){
        return true
      } else {
        return errorMessage
      } 
    },
    loadRecordStored(){
      this.$store.commit('setListTasksMaintenance', []);
      if(this.$store.state.liftsStore.liftRecordProps['date'] && this.$store.state.liftsStore.liftRecordProps['lift_id']){
        this.$store.dispatch("getLiftRecordStored").then(() => {
          this.$store.dispatch("updateListRecordTests", {lift_id: this.$store.state.liftsStore.liftRecordProps['lift_id'], date: this.$store.state.liftsStore.liftRecordProps['date']})
          if('lift_usage_id' in this.$store.state.liftsStore.liftRecord){
            this.$store.dispatch("updateListTasksMaintenance", {record_created: this.$store.state.liftsStore.liftRecord['lift_usage_id']})
          }
        })
        
        this.displayRecord = true
      } else {
        this.displayRecord = false
      }
    },
    close(role) {
      this.dialog_final_signature[role] = false
    },
    final_sign(role) {
      this.updateValueRecord(role, !this.$store.state.liftsStore.liftRecord[role])
      this.close(role)
    },
    allTestsDone(tests){
      const situations = ['on_stop', 'empty', 'in_use']
      let numberTotalTests = 0
      let numberTotalTestsDone = 0
      tests.forEach(test => {
        situations.forEach(situation => {
          if(test['verification_' + situation]){
            numberTotalTests++
            if(test['value_test_' + situation]){
              numberTotalTestsDone++
            }
          }
        })
      })
      return [numberTotalTests, numberTotalTestsDone, numberTotalTests == numberTotalTestsDone]
    }
  },
  mounted(){
    this.$store.dispatch("getLiftsList", this.date);
    this.$store.dispatch("updateListRMPersons");
    this.loadRecordStored()   
  },
}
</script>

<style>
  td .v-input--selection-controls__input {
    margin: 0px !important;
  }
</style>