<template>
    <v-container>
      <v-card class="mb-1">
        <highcharts :options="{
          chart: {
              type: 'spline'
          },
          credits: {
            enabled: false
          },
          title: {
              text: 'Durée des pannes par jour'
          },
          xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            },
            labels: {
              format: '{value:%d/%m/%Y}'
            }
          },
          yAxis: {
              title: {
                  text: 'Durée totale des pannes (min)'
              },
              min: 0
          },
          tooltip: {
              headerFormat: '<b>Durée des pannes:</b><br>',
              pointFormat: '{point.x:%d/%m/%Y}: {point.y} min'
          },
          series: [{
            showInLegend: false,
            data: listDataStatsRecords.filter(el => el['people_count_end_day_montee'] != null).map(el => [Date.parse(el['date_usage']), el['total_break_time'] / 60])
          }]
        }"></highcharts>
      </v-card>
      <v-card>
        <highcharts :options="{
          chart: {
              type: 'spline'
          },
          title: {
              text: 'Nombre de passages par jour'
          },
          credits: {
            enabled: false
          },
          xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            },
            labels: {
              format: '{value:%d/%m/%Y}'
            }
          },
          yAxis: {
              title: {
                  text: 'Nombre de passages'
              },
              min: 0
          },
          tooltip: {
              headerFormat: '<b>Nombre de passages:</b><br>',
              pointFormat: '{point.x:%d/%m/%Y}: {point.y} personnes'
          },
          series: [{
            showInLegend: false,
            data: listDataStatsRecords.filter(el => el['people_count_end_day_montee'] != null).map(el => [Date.parse(el['date_usage']), el['people_count_end_day_montee']])
          }]
        }"
        ></highcharts>
      </v-card>
    </v-container>
</template>

<script>
  import {Chart} from 'highcharts-vue'
  export default {
    name: "StatsLift",
    components: {
      highcharts: Chart 
    },
    computed: {
      listDataStatsRecords(){
        return this.$store.state.liftsStore.listDataStatsRecords
      }
    },
    mounted(){
      this.$store.dispatch("updateStatsLifts")
    }
  }
</script>