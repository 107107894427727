<template>
    <div>
        <table-slopes></table-slopes>
    </div>
</template>

<script>
import TableSlopes from '../components/TableSlopes.vue'


export default {
  name: 'OpenSlopes',
  components: {
    TableSlopes
  }
}
</script>