<template>  
    <v-dialog
        v-model="dialog"
        max-width="900px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="error"
            large
            v-bind="attrs"
            v-on="on"
        >
            Créer un nouvel essai
        </v-btn>
        </template>
        <v-card>
        <v-card-title>
            <span v-if="dataToModify == null" class="text-h5">Création d'un essai</span>
            <span v-else class="text-h5">Modification d'un essai</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="formAddCheck" v-model="isAddCheckFormValid">
            <v-container>
                <v-row>
                <v-col
                    cols="12"
                    md="4"
                >
                <template>
                <v-select
                    v-model="lifts_selected"
                    :items="lift_list"
                    item-text='lift_name'
                    item-value='lift_id'
                    label="Remontée(s)"
                    multiple
                >
                    <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggle_lift"
                    >
                        <v-list-item-content>
                        <v-list-item-title>
                            Tout sélectionner
                        </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
                </template>
                </v-col>
                <v-col cols="12" md="4">
                    <v-combobox
                    v-model="category_selected"
                    :items="checks_categories"
                    label="Catégorie"
                    :rules="[checkIsRequired(category_selected)]"
                    :disabled="dataToModify != null"
                    ></v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                    v-model="test_name"
                    label="Nom de la tâche"
                    :rules="[checkIsRequired(test_name)]"
                    :disabled="dataToModify != null"
                    ></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <p>Les checks sont à effectuer :</p>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                            v-model="checks_situation"
                            multiple
                            :disabled="dataToModify != null"
                        >
                            <v-btn :disabled="dataToModify != null" value="stop">
                            <span>A l'arrêt</span>
                            </v-btn>
                            <v-btn :disabled="dataToModify != null" value="empty">
                            <span>A vide</span>
                            </v-btn>
                            <v-btn :disabled="dataToModify != null" value="during">
                            <span>En exploitation</span>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            >
            Annuler
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            :disabled="!isAddCheckFormValid"
            @click="save"
            >
            Sauvegarder
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogCreateTest",
    props: {
        dataToModify: Object,
    },
    data () {
      return {
        isAddCheckFormValid: false,
        dialog: false,
        lifts_selected: [],
        checks_categories: ["Station motrice", "Véhicules", "Transmission", "Frein service (frein 1)", "Frein poulie Motrice (frein 2)", "Groupe de sécurité", "Station de tension", "Tension hydraulique", "Ligne", "Station retour"],
        category_selected: "",
        test_name: "",
        checks_situation: [],
        test_id: null,
      }        
    },
    computed: {
        lift_list(){
            return this.$store.state.liftsStore.liftsList
        },
        all_lifts_selected () {
            return this.lifts_selected.length === this.lift_list.length
        },
    },
    watch:{
        //We use a watch because dialog can be set to false with calling close if we click outside box
        dialog(val){
            if(!val){
                this.$emit('closeCreationTest')
                this.lifts_selected = []
                this.category_selected = ""
                this.test_name = "",
                this.checks_situation = []
                this.test_id = null
            }
        },
        dataToModify(val){
            if(val != null){
                this.dialog = true
                this.test_name = val['test_name']
                this.category_selected = val['test_category']
                this.lifts_selected = val['lifts_id_selected']
                this.checks_situation = [...(val.verification_on_stop ? ['stop']:[]), ...(val.verification_empty ? ['empty']:[]), ...(val.verification_in_use ? ['during']:[])]
                this.test_id = val['test_id']
            }
        }
    },
    methods: {
        checkIsRequired: function(value, errorMessage) {
            if (!errorMessage) {
                errorMessage = "Ce champ est nécessaire";
            }
            return !!value || errorMessage;
        },
        toggle_lift () {
            if (this.all_lifts_selected) {
                this.lifts_selected = []
            } else {
                this.lifts_selected = this.lift_list.map(x => x.lift_id)
            }
        },
        close () {
            this.dialog = false
        },
        async save () {
            await this.$store.dispatch('addNewTest', {lifts_selected: this.lifts_selected, category_selected: this.category_selected, test_name: this.test_name, checks_situation: this.checks_situation, test_id: this.test_id}).then(() => {
                this.close()
            })
        },
    },
}
</script>