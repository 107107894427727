<!-- 
frontend\src\components\Map.vue
-->
<template>
    <div>
        <div id="mapContainer">
            <!--
        <l-map
        :center="center"
        :zoom="zoom"
        class="map"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        >
            <l-tile-layer
                :url="urlMap"
            >
            </l-tile-layer>
            <l-geo-json :geojson="geojsonSlopes" :options="mapOptionsSlopes"></l-geo-json>
            <l-geo-json :geojson="geojsonLifts" :options="mapOptionsLifts"></l-geo-json>
            <l-marker :lat-lng="currentPosition"></l-marker>
        </l-map>
        -->
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerIcon from "leaflet/dist/images/marker-icon.png"
import data_pistes from '@/assets/data_pistes.json'
import data_RM from '@/assets/data_RM.json'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: "MapLifts",
    data () {
        return {
            currentPosition: [0, 0],
            gettingLocation: false,
            urlMap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            geojsonSlopes: data_pistes,
            geojsonLifts: data_RM,
            center: [ 45.215, 6.212 ],
            zoom: 13.6,
            store: this.$store,
        }
    },
    methods: {
        colorSlope(feature){
            switch(feature.properties['COULEUR'].toLowerCase()) {
                case 'verte':
                    return 'green'
                case 'bleue':
                    return 'blue'
                case 'rouge':
                    return 'red'
                case 'noire':
                    return 'black'
                default:
                    return 'yellow'
            }
        },
        statusSlope(feature){
            const slopesAssociated = this.$store.state.slopesStore.slopesMetaData.filter(slope => slope.slope_map_id == feature.properties['@id'])
            if(slopesAssociated.length > 0){
                feature.properties['slopeAssociated'] = slopesAssociated[0]
                if(slopesAssociated[0].opener == 'Fermé' || (slopesAssociated[0].closer != null && slopesAssociated[0].closer.length > 0)){
                    return 'red'
                }
                if(slopesAssociated[0].opener == null){
                    return 'orange'
                }
                if(slopesAssociated[0].opener.length > 0){
                    return 'green'
                }
            }
            return 'black'
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center) {
            this.center = center;
        },
        openTabLift(feature){
            for(let i in this.$store.state.liftsStore.liftsList){
                if(this.$store.state.liftsStore.liftsList[i].lift_map_id == feature.properties['@id']){
                    this.$emit('openTab', this.$store.state.liftsStore.liftsList[i])
                    break
                }
            }
        },
        getCoordsMiddleLift(coords){
            const lengthPoints = ([x1, y1], [x2, y2]) => Math.sqrt((x2-x1)**2+(y2-y1)**2);
            let totalLen = 0
            for(var i = 1; i < coords.length; i++){
                totalLen += lengthPoints(coords[i], coords[i-1])
            }
            let lenTemp = 0
            for(i = 1; i < coords.length; i++){
                const localDist = lengthPoints(coords[i], coords[i-1])
                if (lenTemp + localDist > totalLen/2) {
                    return [coords[i-1][1] + (totalLen/2 - lenTemp)/localDist * (coords[i][1] - coords[i-1][1]), coords[i-1][0] + (totalLen/2 - lenTemp)/localDist * (coords[i][0] - coords[i-1][0])]
                } else {
                    lenTemp += localDist
                }
            }
        },
        defaultStyleSlopes(feature) {
            return {
                weight: 1,
                opacity: 0.9,
                //fillColor: this.colorSlope(feature),
                fillOpacity: 0.6,
                color: this.statusSlope(feature),
            };
        }
    },
    updated() {
        L.Icon.Default.prototype.options.iconUrl = MarkerIcon;
        //do we support geolocation
        if (!("geolocation" in navigator)) {
          this.errorStr = "Geolocation is not available.";
          return;
        }
        // get position
        /*
        navigator.geolocation.watchPosition(
            pos => {
                this.currentPosition = [pos.coords.latitude, pos.coords.longitude]
                this.gettingLocation = true;
            },
            err => {
                this.gettingLocation = false;
                this.$store.dispatch("addNotification", {message: "Erreur de géolocalisation :"+err.message, type: "error"});
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
            }
        );
        */
    },
    async mounted(){
        await Promise.all([this.$store.dispatch("getLiftsList", new Date()), this.$store.dispatch("getLiftsStates", new Date()), this.$store.dispatch("updateSlopesMetaData", ('0' + new Date().getDate()).slice(-2)+'/'+('0' + (new Date().getMonth()+1)).slice(-2)+'/'+new Date().getFullYear())]).then(() => {
            const osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap'
            })

            const satelit = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                maxZoom: 19,
                attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            })

            var statusLifts = L.layerGroup([])


            const mapOptionsSlopes = {
                style: this.defaultStyleSlopes,
                onEachFeature: (function onEachFeature(feature, layer) {
                    let text = ''
                    if(feature.properties['slopeAssociated']){
                        text += '<h1 style="color: grey; margin-bottom: 0;">'+ feature.properties['slopeAssociated']['slopeName'] +'</h1>' + '<br>'
                        if(feature.properties['slopeAssociated']['opener'] == null){
                            text += "En attente d'ouverture" + '<br>'
                        } else if(feature.properties['slopeAssociated']['opener'] == 'Fermé'){
                            text += 'Fermée' + '<br>'
                        } else {
                            text += 'Ouverte par '+ feature.properties['slopeAssociated']['opener'] + '<br>'
                            if(feature.properties['slopeAssociated']['closer'] != null) {
                                text += 'Fermée par '+ feature.properties['slopeAssociated']['closer'] + '<br>'
                            }
                        }
                    } else {
                        text += '<h1 style="color: grey; margin-bottom: 0;">'+ feature.properties['NOM'] +'</h1>'
                        text += 'Piste non associée, veuillez créer une piste avec l\'id sur la carte suivant : ' + feature.properties['@id'] + '<br>'
                    }
                    
                    layer.bindPopup(text, {'offset': L.point(-50,-50)}) 
                        
                    layer.on('mouseover', (e) => {
                        var p = L.point([e.originalEvent.clientX,e.originalEvent.clientY])
                        var latlng = map.containerPointToLatLng(p);
                        layer.openPopup(latlng)
                        layer.setStyle({
                            fillOpacity: 1,
                            opacity: 1,
                            weight: 5
                        });
                    });
                    layer.on('mouseout', () => {
                        layer.closePopup()
                        layer.setStyle(this.defaultStyleSlopes(feature));
                    });
                }).bind(this),
            }
            const mapOptionsLifts = {
                style: {
                    weight: 8,
                    opacity: 0.7,
                    color: 'indigo',
                    fillOpacity: 0.3
                },   
                onEachFeature: (function onEachFeature(feature, layer) {
                    let text = ''
                    let found = false
                    let liftProps = null
                    for(let j in this.$store.state.liftsStore.liftsList){
                        if(this.$store.state.liftsStore.liftsList[j].lift_map_id == feature.properties['@id']){
                            found = true
                            liftProps = this.$store.state.liftsStore.liftsList[j]
                            const colorCircle = this.$store.getters.color_state_lift(liftProps.lift_id)['color']
                            var circle = L.circle(this.getCoordsMiddleLift(feature.geometry.coordinates), 80, {
                                color: colorCircle,
                                fillColor: colorCircle,
                                fillOpacity: 0.8,
                            }).bindPopup(text);
                            circle.on('click', () => {
                                this.$emit('openTab', liftProps)
                            });
                            statusLifts.addLayer(circle)

                            layer.on('click', () => {
                                this.$emit('openTab', liftProps)
                            });
                            break
                        }
                    }
                    if(found){
                        text += '<h1 style="color: grey; margin-bottom: 0;">'+ liftProps.lift_name +'</h1>'
                        text += liftProps.lift_type + '<br>'
                        text += liftProps.opening_time_def + '<br>'
                        text += liftProps.closing_time_def + '<br>'
                        text += '(ajouté le ' + liftProps.added_date + ')'
                    } else {
                        text += '<h1 style="color: grey; margin-bottom: 0;">'+ feature.properties['NOM'] +'</h1>'
                        text += 'Remontée non associée à un appareil, veuillez créer une remontée avec l\'id sur la carte suivant : ' + feature.properties['@id']
                    }
                    layer.bindPopup(text).openPopup(); 
                }).bind(this),
            }

            const slopes = L.geoJson(this.geojsonSlopes, mapOptionsSlopes)
            const lifts = L.geoJson(this.geojsonLifts, mapOptionsLifts)

            const map = L.map("mapContainer", {
                center: this.center,
                zoom: this.zoom,
                layers: [osm, slopes, lifts, statusLifts]
            });

            var baseMaps = {
                "OpenStreetMap": osm,
                "Satellite": satelit
            }
            var overlayMaps = {
                "Pistes": slopes,
                "RMs": lifts,
                "Statuts RMS": statusLifts,
            }
            L.control.layers(baseMaps, overlayMaps).addTo(map);
        })
    }
}
</script>

<style>
    #mapContainer {
        width: 100%;
        height: 90%;
        position: absolute;
        left: 0px;
        overflow: hidden;
        z-index: 1;
    }
</style>