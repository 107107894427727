<template>
    <v-container>
        <h1 style="color: white; text-align: left;">Commentaires</h1>
        <historic-comments-lift></historic-comments-lift>
        <h1 style="color: white; text-align: left;">Pannes / Arrêts</h1>
        <historic-breakdowns-lift></historic-breakdowns-lift>
    </v-container>
</template>

<script>
import HistoricCommentsLift from '@/components/HistoricCommentsLift'
import HistoricBreakdownsLift from '@/components/HistoricBreakdownsLift'
export default {
    name: "HistoricLift",
    components: {
        HistoricCommentsLift,
        HistoricBreakdownsLift,
    },
    data () {
      return {
      }
    },
    computed: {
      liftId(){
        return this.$store.state.liftsStore.selectedLiftCentral.lift_id
      }
    }
}
</script>