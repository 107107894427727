<!-- 
frontend\src\views\ManageAccounts.vue
-->
<template>
  <v-container grid-list-xs>
    <v-layout row wrap justify-center align-center>
      <v-flex>
        <table-accounts></table-accounts>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TableAccounts from '../components/TableAccounts.vue';
export default {
  name: "ManageAccounts",
  components: {
    TableAccounts
  }
};
</script>