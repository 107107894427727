<template>  
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            small
            v-bind="attrs"
            v-on="on"
        >
            Créer piste
        </v-btn>
        </template>
        <v-card>
        <v-card-title>
            <span v-if="dataToModify == null" class="text-h5">Ajout nouvelle piste</span>
            <span v-else class="text-h5">Modifier piste</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="formAddSlope" v-model="isAddSlopeFormValid">
            <v-container>
                <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="slopeName"
                        label="Nom piste"
                        :rules="[checkIsRequired(slopeName)]"
                        required
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='sectorsSelect'
                        item-text="text"
                        item-value="value"
                        v-model="area"
                        label="Secteur"
                        :rules="[checkIsRequired(area)]"
                        required
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='[{text:"Vert", value:"green"}, {text:"Bleu", value:"blue"}, {text:"Rouge", value:"red"}, {text:"Noir", value:"black"}, {text:"Autre", value:"yellow"}]'
                        item-text="text"
                        item-value="value"
                        v-model="color"
                        label="Couleur"
                        :rules="[checkIsRequired(color)]"
                        required
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="openingTimeDef"
                        type="time"
                        label="Heure d'ouverture par défaut"
                        :rules="[checkIsRequired(openingTimeDef)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="closingTimeDef"
                        type="time"
                        label="Heure de fermeture par défaut"
                        :rules="[checkIsRequired(closingTimeDef)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="mapId"
                        label="Id sur la carte"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            >
            Annuler
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            :disabled="!isAddSlopeFormValid"
            @click="save"
            >
            Sauvegarder
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogCreateSlope",
    data () {
      return {
        sectorsSelect: [{text: "Corbier/St-Sorlin", value: parseInt(101, 2)}, {text: "St-Sorlin", value: parseInt(100, 2)}, {text: "SJA", value: parseInt(10, 2)}, {text: "Corbier", value: parseInt(1, 2)}],
        dialog: false,
        slopeName: '',
        area: '',
        color: '',
        openingTimeDef: '09:00:00',
        closingTimeDef: '17:00:00',
        mapId: '',
        isAddSlopeFormValid: false,
      }        
    },
    props: {
        dataToModify: Object,
    },
    watch: {
        //We use a watch because dialog can be set to false with calling close if we click outside box
        dialog(val){
            if(!val){
                this.$emit('closeCreationSlope')
                this.slopeName = ''
                this.area = ''
                this.color = ''
                this.openingTimeDef = '09:00:00'
                this.closingTimeDef = '17:00:00'
                this.mapId = ''
            }
        },
        dataToModify(val){
            if(val != null){
                this.dialog = true
                this.slopeName = val['slopeName']
                this.area = val['area_value']
                this.color = val['color']
                this.openingTimeDef = val['openingTimeDef']
                this.closingTimeDef = val['closingTimeDef']
                this.mapId = val['slope_map_id']
            }
        }
    },
    methods: {
        checkIsRequired: function(value, errorMessage) {
            if (!errorMessage) {
                errorMessage = "Ce champ est nécessaire";
            }
            return !!value || errorMessage;
        },
        close () {
            this.dialog = false
        },
        async save () {
            if(this.dataToModify == null){
                await this.$store.dispatch("addSlope", {"slopeName":this.slopeName, "area":this.area, "color":this.color, "openingTimeDef":this.openingTimeDef, "closingTimeDef":this.closingTimeDef, 'slope_map_id': this.mapId, "date": this.$store.state.slopesStore.dateSlopes}).then(() => {
                    this.close()
                })
            } else {
                await this.$store.dispatch("modifySlope", {"slopeId": this.dataToModify["slopeId"], "modified_from_slope_id": this.dataToModify["modified_from_slope_id"], "slopeName":this.slopeName, "area":this.area, "color":this.color, "openingTimeDef":this.openingTimeDef, "closingTimeDef":this.closingTimeDef, 'slope_map_id': this.mapId, "date": this.$store.state.slopesStore.dateSlopes}).then(() => {
                    this.close()
                })
            }
        },
    }
}
</script>