<template>  
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            small
            v-bind="attrs"
            v-on="on"
        >
            Créer nouvel appareil
        </v-btn>
        </template>
        <v-card>
        <v-card-title>
            <span class="text-h5">Ajout nouvel appareil</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="formAddLift" v-model="isAddLiftFormValid">
            <v-container>
                <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="liftName"
                        label="Nom remontée"
                        :rules="[checkIsRequired(liftName)]"
                        required
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='sectorsSelect'
                        item-text="text"
                        item-value="value"
                        v-model="area"
                        label="Secteur"
                        :rules="[checkIsRequired(area)]"
                        required
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='[{text:"TSD", value:"tsd"}, {text:"TSF", value:"tsf"}, {text:"TK", value:"tk"}, {text:"Tapis", value:"tapis"}, {text:"TC", value:"tc"}, {text:"Autre", value:"autre"}]'
                        item-text="text"
                        item-value="value"
                        v-model="liftType"
                        label="Type"
                        :rules="[checkIsRequired(liftType)]"
                        required
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="openingTimeDef"
                        type="time"
                        label="Heure d'ouverture par défaut"
                        :rules="[checkIsRequired(openingTimeDef)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="closingTimeDef"
                        type="time"
                        label="Heure de fermeture par défaut"
                        :rules="[checkIsRequired(closingTimeDef)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="mapId"
                        label="Id sur la carte"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            >
            Annuler
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            :disabled="!isAddLiftFormValid"
            @click="save"
            >
            Sauvegarder
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogCreateLift",
    data () {
      return {
        sectorsSelect: [{text: "Corbier/St-Sorlin", value: parseInt(101, 2)}, {text: "St-Sorlin", value: parseInt(100, 2)}, {text: "SJA", value: parseInt(10, 2)}, {text: "Corbier", value: parseInt(1, 2)}],
        dialog: false,
        liftName: '',
        area: '',
        liftType: '',
        mapId: '',
        openingTimeDef: '09:00:00',
        closingTimeDef: '17:00:00',
        isAddLiftFormValid: false,
      }        
    },
    methods: {
        checkIsRequired: function(value, errorMessage) {
            if (!errorMessage) {
                errorMessage = "Ce champ est nécessaire";
            }
            return !!value || errorMessage;
        },
        close () {
            this.dialog = false
            this.liftName = ''
            this.area = ''
            this.liftType = ''
        },
        async save () {
            await this.$store.dispatch("addLift", {"lift_name":this.liftName, "lift_map_id": this.mapId, "area":this.area, "lift_type":this.liftType, "opening_time_def":this.openingTimeDef, "closing_time_def":this.closingTimeDef}).then(() => {
                this.close()
            })
        },
    }
}
</script>