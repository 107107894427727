<template>  
    <v-dialog
        v-model="dialog"
        max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            x-large
            depressed
            color="error"
            v-bind="attrs"
            v-on="on"
        >
            Déclarer une panne
        </v-btn>
        </template>
        <v-card>
        <v-card-title>
            <span class="text-h5">Déclaration de panne</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="formDeclareBreakout" v-model="isBreakoutFormValid">
            <v-container>
                <v-row>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="breakout_start"
                        type="time"
                        label="Heure de début de panne"
                        :rules="[checkIsRequired(breakout_start)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                    <v-text-field
                        v-model="breakout_end"
                        type="time"
                        label="Heure de fin de panne"
                        :rules="[checkIsRequired(breakout_end)]"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='causeSelect'
                        v-model="breakout_cause"
                        item-text="text"
                        item-value="text"
                        label="Cause de la panne"
                        :rules="[checkIsRequired(breakout_cause)]"
                        required
                        >
                            <template slot="selection" slot-scope="data">
                                <div>
                                    <v-icon v-if="data.item.icon">{{ data.item.icon }}</v-icon>
                                    {{ data.item.text }}
                                </div>
                            </template>
                            <template slot="item" slot-scope="data">
                                <div>
                                    <v-icon v-if="data.item.icon">{{ data.item.icon }}</v-icon>
                                    {{ data.item.text }}
                                </div>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-select
                        :items='personelOnLift'
                        item-value="id"
                        item-text="name"
                        v-model="breakout_stopper"
                        label="Personne à l'origine de l'arrêt"
                        >
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-text-field
                        v-model="breakout_comment"
                        label="Commentaire"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            >
            Annuler
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            :disabled="!isBreakoutFormValid"
            @click="save"
            >
            Sauvegarder
            </v-btn>
        </v-card-actions>
        <historic-breakdowns-lift></historic-breakdowns-lift>
        </v-card>
    </v-dialog>
</template>

<script>
import HistoricBreakdownsLift from "@/components/HistoricBreakdownsLift"
export default {
    name: "DialogDeclareBreakout",
    components: {
        HistoricBreakdownsLift
    },
    data () {
      return {
        dialog: false,
        isBreakoutFormValid: false,
        causeSelect: [{text: 'Mauvaise utilisation client', icon: 'mdi-account-alert'}, {text: 'Météo', icon: 'mdi-weather-cloudy'}, {text: 'Portillons', icon: 'mdi-lock-open-variant'}, {text: 'Panne électrique', icon: 'mdi-lightning-bolt-circle'}, {text: 'Panne mécanique', icon: 'mdi-wrench'}, {text: 'Autre'}],
        breakout_start: '',
        breakout_end: '',
        breakout_cause: '',
        breakout_comment: '',
        breakout_stopper: null,
      }        
    },
    watch: {
        dialog(newVal){
            if(newVal){
                this.$store.dispatch('updateListBreakdowns', {date: new Date(), lift_id: this.$store.state.liftsStore.liftRecordProps['lift_id']})
            }
        }
    },
    computed: {
        personelOnLift(){
            var arr = this.$store.state.liftsStore.listRMPersons;
            return arr;
        }
    },
    methods: {
        checkIsRequired: function(value, errorMessage) {
            if (!errorMessage) {
                errorMessage = "Ce champ est nécessaire";
            }
            return !!value || errorMessage;
        },
        close () {
            this.dialog = false
            this.breakout_start = ''
            this.breakout_end = ''
            this.breakout_cause = ''
            this.breakout_comment = ''
            this.breakout_stopper = null
        },
        async save () {
            await this.$store.dispatch("addBreakdown", {lift_id: this.$store.state.liftsStore.liftRecordProps['lift_id'], date: this.$store.state.liftsStore.liftRecordProps['date'], time_breakdown: this.breakout_start, time_recovery: this.breakout_end, cause: this.breakout_cause, comment: this.breakout_comment, stopper: this.breakout_stopper}).then(() => {
                this.close()
                this.$store.dispatch('updateListBreakdowns', {date: new Date(), lift_id: this.$store.state.liftsStore.liftRecordProps['lift_id']})
            })
        },
    },
}
</script>