<template>
    <v-container fluid>
        <v-row style="height: calc(100vh - 48px);">
            <v-col id="leftCol" cols="12" :sm="isOpenTab?3:12" :md="isOpenTab?5:12" v-if="!isOpenTab || $vuetify.breakpoint.smAndUp" :style="'height: 100%; position: relative; padding-right: 20px; max-width: 100%; '+sizeLeftCol" class="overflow-auto">
                <v-row align="center">
                    <v-switch
                        v-model="switchMapList"
                        prepend-icon="mdi-list-box-outline"
                        append-icon="mdi-map"
                        color="grey darken-1"
                        style="width: 105px;"
                    >
                    </v-switch>
                    <v-spacer></v-spacer>
                    <v-datetime-picker label="Date/Heure" clearText="Annuler" dateFormat="dd/MM/yyyy" :timePickerProps="{format:'24hr'}" v-model="datetime">
                        <template v-slot:dateIcon>
                            <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template v-slot:timeIcon>
                            <v-icon>mdi-clock</v-icon>
                        </template>
                    </v-datetime-picker>
                    <v-spacer></v-spacer>
                    <dialog-create-lift></dialog-create-lift>
                    <v-btn color="primary" class="ml-2" v-if="!switchMapList" dark small @click="modifyMode">Modifier remontées</v-btn>
                </v-row>
                <v-row>
                    <list-lifts :is-modify-mode="modifyLiftsMode" v-if="!switchMapList" @openTab="openTab"></list-lifts>
                    <map-lifts v-else @openTab="openTab"></map-lifts>
                </v-row>
            </v-col>
            <v-slide-x-reverse-transition>
            <v-col id="rightCol" cols="12" sm="9" md="7" v-if="isOpenTab" :style="'background-color: #194f73; height: 100%; padding: 0px; max-width: 100%; '+sizeRightCol" class="overflow-y-auto">
                <v-btn @click="closeTab" v-if="$vuetify.breakpoint.smAndUp" absolute small fab style="display: flex; transform: translate(-50%); top: calc(50% - 54px);" class="align-self-center">
                    <v-icon large>
                        mdi-close
                    </v-icon>
                </v-btn>
                <v-btn @mousedown="startResize" @touchstart="startResize" @mouseup="stopResize" @touchend="stopResize" @mousemove="doResizeCol" @touchmove="doResizeCol" v-if="$vuetify.breakpoint.smAndUp" absolute small fab style="display: flex; transform: translate(-50%); top: calc(50% + 6px)" class="align-self-center">
                    <v-icon large>
                        mdi-arrow-left-right
                    </v-icon>
                </v-btn>
                <v-btn v-else block @click="closeTab" >
                    <v-icon large>
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
                <v-toolbar
                v-if="liftName != ''"
                dark
                dense
                >
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="align-center">{{ liftName }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-tabs icons-and-text fixed-tabs dark show-arrows v-model="currentPageTab">
                    <v-tab>
                        Registres
                        <v-icon>mdi-book-open</v-icon>
                    </v-tab>
                    <v-tab>
                        Statistiques
                        <v-icon>mdi-chart-line</v-icon>
                    </v-tab>
                    <v-tab>
                        Historiques
                        <v-icon>mdi-comment-search</v-icon>
                    </v-tab>
                    <v-tab>
                        Tâches Entretien
                        <v-icon>mdi-list-box-outline</v-icon>
                    </v-tab>
                    <v-tab v-if="$store.state.liftsStore.selectedLiftCentral.lift_id">
                        Tâches Routine
                        <v-icon>mdi-clipboard-list-outline</v-icon>
                    </v-tab>
                </v-tabs>
                <v-container>
                    <table-historic-registres-lift v-if="currentPageTab == 0"></table-historic-registres-lift>
                    <stats-lift v-else-if="currentPageTab == 1"></stats-lift>
                    <historic-lift v-if="currentPageTab == 2"></historic-lift>
                    <tasks-maintenance-lift v-else-if="currentPageTab == 3"></tasks-maintenance-lift>
                    <tasks-lift :liftId.sync="liftId" v-else-if="currentPageTab == 4"></tasks-lift>
                </v-container>
            </v-col>
            </v-slide-x-reverse-transition>
        </v-row>
    </v-container>
</template>

<script>
import MapLifts from '@/components/MapLifts'
import ListLifts from '@/components/ListLifts'
import TableHistoricRegistresLift from '@/components/TableHistoricRegistresLift'
import TasksLift from '@/components/TasksLift'
import TasksMaintenanceLift from '@/components/TasksMaintenanceLift'
import StatsLift from '@/components/StatsLift'
import HistoricLift from '@/components/HistoricLift'
import DialogCreateLift from '@/components/DialogCreateLift'
export default {
  name: "LiftsGlobalDashboard",
  components: {
    MapLifts,
    ListLifts,
    TableHistoricRegistresLift,
    TasksLift,
    TasksMaintenanceLift,
    StatsLift,
    HistoricLift,
    DialogCreateLift
  },
  data() {
    return {
        modifyLiftsMode: false,
        switchMapList: false,
        currentPageTab: '',
        isOpenTab: false,
        resizeCols: false,
        sizeLeftCol: '',
        sizeRightCol: '',
        positionResizeOrigin: null,
        leftColSize: 0,
        rightColSize: 0,
        menu_date_picker: false,
    }
  },
    computed: {
        liftId(){
            return this.$store.state.liftsStore.selectedLiftCentral.lift_id
        },
        liftName(){
            return 'lift_name' in this.$store.state.liftsStore.selectedLiftCentral ? this.$store.state.liftsStore.selectedLiftCentral.lift_name : ''
        },
        datetime: {
            //format : 15/10/2022 10:05
            get() { return this.$store.state.liftsStore.dateLifts.toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) },
            set(value) { 
                const [dateValues, timeValues] = value.split(' ');
                const [day, month, year] = dateValues.split('/');
                const [hours, minutes] = timeValues.split(':');
                const date = new Date(+year, +month - 1, +day, +hours, +minutes);
                this.$store.dispatch('callSetter', {'setterName': 'setLiftsDates', 'value': date}) 
            }
        },
    },
  methods: {
    modifyMode(){
        this.modifyLiftsMode = !this.modifyLiftsMode
    },
    openTab(lift){
        if(Number(lift.lift_id) == Number(this.liftId)){
            this.$store.commit('setSelectedLiftCentral', {'lift_id': null})
        } else {
            this.isOpenTab = true
            this.$store.commit('setSelectedLiftCentral', lift)
        }
        this.$store.dispatch("updateListLiftRecords");
        this.$store.dispatch('updateListBreakdowns');
        this.$store.dispatch('updateListCommentsRecords');
        this.$store.dispatch('updateListTasksMaintenance');
        this.$store.dispatch("updateStatsLifts");
    },

    closeTab(){
        this.sizeLeftCol = ''
        this.sizeRightCol = ''
        this.isOpenTab = false
        this.$store.commit('setSelectedLiftCentral', {'lift_id': null})
    },
    doResizeCol(e){
        let pageX = null
        if('pageX' in e){
            pageX = e.pageX
        } else if('targetTouches' in e){
            pageX = e.targetTouches[0].pageX
        }
        if(this.resizeCols){
            this.leftColSize += (pageX - this.positionResizeOrigin)
            this.rightColSize -= (pageX - this.positionResizeOrigin)
            this.sizeLeftCol = "flex-basis: " + this.leftColSize + "px;"
            this.sizeRightCol = "flex-basis: " + this.rightColSize + "px;"
            this.positionResizeOrigin = pageX
        }
    },
    startResize(e){
        let pageX = null
        if('pageX' in e){
            pageX = e.pageX
        } else if('targetTouches' in e){
            pageX = e.targetTouches[0].pageX
        }
        this.resizeCols = true;
        this.positionResizeOrigin = pageX
        this.leftColSize = document.getElementById('leftCol').offsetWidth
        this.rightColSize = document.getElementById('rightCol').offsetWidth - 1
    },
    stopResize(){
        this.resizeCols = false
    }
  },
  mounted(){
    document.addEventListener('mouseup', this.stopResize)
    document.addEventListener('mousemove', this.doResizeCol)
    //Date format is 2022-02-25
    this.$store.dispatch("getLiftsList", this.$store.state.liftsStore.dateLifts);
    this.$store.dispatch("getLiftsStates", this.$store.state.liftsStore.dateLifts);
    this.isOpenTab = true
  },
    beforeDestroy() {
    document.removeEventListener('mouseup', this.stopResize)
    document.addEventListener('mousemove', this.doResizeCol)
  },
}
</script>

<style scoped>
.v-tab {
    font-size: 0.7rem;
}
</style>