<!-- 
frontend\src\views\Historic.vue
-->
<template>
<v-container grid-list-xs>
  <v-layout row wrap justify-center align-center>
    <v-flex xs12 md8 lg8>
      <table-download></table-download>
    </v-flex>
  </v-layout>
</v-container>

</template>

<script>
import TableDownload from '../components/TableDownload.vue';
export default {
  name: "Historic",
  components: {
    TableDownload
  }
};
</script>