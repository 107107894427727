<template>
    <v-container>
        <v-data-table
            :headers="headersPannes"
            :items="pannes"
            :search="search"
            :options="{sortBy: ['date'],sortDesc: [true]}"
            mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              class="ml-4 mr-4"
            ></v-text-field>
          </template>
          <template v-slot:item.stopper="props">
            {{ props.item.firstname }} {{ props.item.surname }}
          </template>
        </v-data-table>
    </v-container>
</template>

<script>
  export default {
    name: "HistoricCommentsLift",
    data () {
      return {
        search: '',
        headersPannes: [
            {text: "Date", value: 'date'},
            {text: "Appareil", value: 'lift_name', sortable: true},
            {text: "Heure d'arrêt", value: 'time_breakdown', sortable: true},
            {text: "Heure de reprise", value: 'time_recovery', sortable: true},
            {text: "Durée d'arrêt", value: 'deltaTime', sortable: true},
            {text: "Cause", value: 'cause', sortable: true},
            {text: "Commentaire", value: 'comment', sortable: true},
            {text: "Stoppeur", value: 'stopper', sortable: true},
        ]
      }
    },
    computed: {
      pannes(){
        const p = "1/1/1970 ";
        const result = this.$store.state.liftsStore.listBreakdowns.map(v => ({...v, deltaTime: new Date(new Date(p+v.time_recovery) - new Date(p+v.time_breakdown)).getTime() / (1000*60) + ' min.'}))
        return result
      }
    },
  }
</script>