<template>
    <div>
      <v-container>
        <v-row>
          <v-col cols="12">
            <dialog-create-test :dataToModify="dataToModify" @closeCreationTest="dataToModify = null"></dialog-create-test>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="my-auto">
            <span class="font-weight-bold">Filtres :</span>
          </v-col>
          <v-col cols="5" md="3" class="my-auto">
            <template>
              <v-select
                v-model="category_filter_value"
                :items="checks_categories"
                label="Catégories de tâches"
                dense
                hide-details
                outlined
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle_category"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Tout sélectionner
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </template>
          </v-col>
          <v-col cols="5" md="3" class="my-auto">
            <template>
              <v-select
                v-model="lift_filter_value"
                :items="lift_list"
                item-text='lift_name'
                item-value='lift_name'
                label="Remontée(s)"
                outlined
                dense
                hide-details
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle_lift"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Tout sélectionner
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </template>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table 
                    :headers="headers"
                    :items="check_list"
                    class="elevation-2"
                    mobile-breakpoint="0">
                    <template v-slot:item.lifts_name_selected="props">
                      <v-menu
                      v-if="props.item.lifts_name_selected"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            rounded
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            Voir les {{ props.item.lifts_name_selected.length }} RM
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item,index) in props.item.lifts_name_selected"
                            :key="index"
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:[`item.verification_${situation}`]="{ item }" v-for="situation in ['in_use', 'empty', 'on_stop']">
                      <v-icon v-if="item[`verification_${situation}`]" color="green" :key="situation">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon v-else color="red" :key="situation">
                        mdi-close-circle-outline
                      </v-icon>
                    </template>
                    <template v-slot:item.actions_test="props">
                      <v-btn icon>
                        <v-icon
                        color="blue"
                        large
                        @click="modifyTest(props.item)">
                          mdi-pencil-circle
                        </v-icon>
                      </v-btn>
                      <v-btn
                      icon>
                        <v-icon
                        color="red"
                        large
                        @click="$store.dispatch('deleteTest', {test_id: props.item.test_id})">
                          mdi-delete-circle
                        </v-icon>
                      </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import DialogCreateTest from '@/components/DialogCreateTest'
export default {
  name: "TestsConfiguration",
  components:{
    DialogCreateTest
  },
  data() {
    return {
      lift_filter_value: [],
      category_filter_value: [],
      type_filter_value: [],
      headers:[
        { text: 'Tâche', align: 'start', sortable: true, value: 'test_name'},
        { text: 'Remontées', align: 'center', sortable: false, value: 'lifts_name_selected',filter: this.lifts_filter},
        { text: 'Catégorie', align: 'center', sortable: true, value: 'test_category',filter: this.category_filter},
        { text: 'Vérification à l\'arrêt', align: 'center', sortable: false, value:'verification_on_stop'},
        { text: 'Vérification à vide', align: 'center', sortable: false, value: 'verification_empty'},
        { text: 'Vérification en exploitation', align: 'center', sortable: false, value: 'verification_in_use'},
        { text: 'Modifier essai', align: 'center', sortable: false, value: 'actions_test'}
      ],
      dataToModify: null,
    }
  },
  mounted() {
    this.$store.dispatch('getLiftsList')
    this.$store.dispatch('updateListAllTests')
  },
  computed: {
    check_list(){
      return this.$store.state.liftsStore.listAllTests
    },
    lift_list(){
      return this.$store.state.liftsStore.liftsList
    },
    checks_categories(){
      return this.check_list.map(item => item.test_category)
    },
    all_lifts_selected () {
      return this.lift_filter_value.length === this.lift_list.length
    },
    all_categories_selected () {
      return this.category_filter_value.length === this.checks_categories.length
    },
  },
  methods: {
    lifts_filter(value) {
      if (this.lift_filter_value.length == 0) {
        return true;
      }
      for (const i of value){
        if (this.lift_filter_value.includes(i)){
          return true
        }
      }
      return false
    },
    category_filter(value) {
      if (this.category_filter_value.length == 0) {
        return true;
      }
      return this.category_filter_value.includes(value);
    },
    toggle_lift () {
      this.$nextTick(() => {
        if (this.all_lifts_selected) {
          this.lift_filter_value = []
        } else {
          this.lift_filter_value = this.lift_list.map(x => x.lift_name)
        }
      })
    },
    toggle_category () {
      this.$nextTick(() => {
        if (this.all_categories_selected) {
          this.category_filter_value = []
        } else {
          this.category_filter_value = this.checks_categories.slice()
        }
      })
    },
    modifyTest(test){
      this.dataToModify = test
    }
  }
}
</script>
