<template>
  <div>
  <v-data-table
  :headers="headers"
  :items="accountsProcessed"
  :items-per-page="15"
  :sortDesc = sortDesc
  :sortBy = sortBy
  :search="search"
  :footer-props="{
  'items-per-page-text':'Nombre de lignes par page',
  'items-per-page-all-text':'Toutes'
  }"
  class="elevation-1"
  mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          class="m-0 p-0 shrink"
        ></v-text-field>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              small
              class="mt-auto ml-auto"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-account-plus</v-icon>
              Ajout utilisateur
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Ajout utilisateur</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-form ref="formAddAccount" v-model="isAddUserFormValid">
                <v-container>
                  <v-row dense>
                    <v-col dense
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        class = "textFieldGrid"
                        name="email"
                        label="Nom d'utilisateur/Email*"
                        id="email"
                        v-model="email"
                        prepend-icon="mdi-account-circle"
                        required
                        :rules="[checkIsRequired(email), checkMinLenght(email,3)]"
                        type="email"
                      ></v-text-field>
                    </v-col>

                    <v-col dense
                      cols="12"
                      md="6"
                    >
                      <v-select
                        class = "textFieldGrid"
                        :items='sectorsSelect'
                        item-text="text"
                        item-value="value"
                        v-model="sector"
                        prepend-icon="mdi-map"
                        label="Secteur"
                        required
                        :rules="[checkIsRequired(sector)]"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col dense
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        class = "textFieldGrid"
                        name="firstname"
                        label="Prénom*"
                        id="firstname"
                        v-model="firstname"
                        :rules="[checkIsRequired(firstname)]"
                      ></v-text-field>
                    </v-col>
                    <v-col dense
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        class = "textFieldGrid"
                        name="surname"
                        label="Nom de famille*"
                        id="surname"
                        v-model="surname"
                        :rules="[checkIsRequired(surname)]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col dense
                      cols="12"
                      md="6"
                    >
                      <v-select
                        class = "textFieldGrid"
                        :items='autorisationSelect'
                        item-text="text"
                        item-value="value"
                        v-model="autorisation"
                        prepend-icon="mdi-account-eye"
                        label="Autorisation"
                        required
                        multiple
                        :rules="[checkIsRequired(autorisation)]"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <div v-if="isProcessing" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!isAddUserFormValid || isProcessing"
                @click="save"
              >
                Sauvegarder
              </v-btn>
            </v-card-actions>

            <v-card-text v-if="listNotifsAccountsCreated.length != 0">
              <v-alert
                type="success"
                dense
                >
                Compte {{ listNotifsAccountsCreated[listNotifsAccountsCreated.length-1].username }} créé avec succès, pensez à copier les mots de passe temporaires ci-dessous !
              </v-alert>


              <v-simple-table dark class="mb-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        Utilisateur créé
                      </th>
                      <th class="text-center">
                        Mot de passe temporaire
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in listNotifsAccountsCreated"
                      :key="item.username"
                    >
                      <td>{{ item.username }}</td>
                      <td>{{ item.password }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-btn small color="primary">
                <v-icon left>mdi-microsoft-excel</v-icon>
                <download-csv
                  class = "btn btn-default"
                  :data   = "listNotifsAccountsCreated"
                  :separator-excel = "true"
                  name = "nouveauxComptesDigiski.csv">
                  Export excel
                </download-csv>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-title>
    </template>
    <template v-slot:item.actionsAccount="props">
      <v-btn icon>
        <v-icon
        color="blue"
        large
        @click="modifyAccount(props.item.userId)">
          mdi-account-edit
        </v-icon>
      </v-btn>
      <v-btn v-if="props.item.userId != currentUserId"
      icon>
        <v-icon
        color="red"
        large
        @click="deleteAccount(props.item.userId)">
          mdi-delete-circle
        </v-icon>
      </v-btn>
    </template>

  </v-data-table>
  </div>  
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "TableAccounts",
    data () {
      return {
        SECTORS: ["St Sorlin", "SJA", "Corbier"],
        sectorsSelect: [{text: "St-Sorlin", value: parseInt(100, 2)}, {text: "Corbier/SJA", value: parseInt(11, 2)}, {text: "Corbier/SJA/St-Sorlin", value: parseInt(111, 2)}, {text: "Aucun", value: parseInt(0, 2)}],
        autorisation: [],
        sortBy: "surname",
        sortDesc: false,
        search: '',
        dialog: false,
        email: '',
        firstname: '',
        surname: '',
        sector: '',
        isAddUserFormValid: false,
      }        
    },
    computed: {
      ...mapGetters([
        "isProcessing",
        "listNotifsAccountsCreated",
      ]),
      autorisationSelect(){
        return this.$store.state.authStore.rolesAvailable
      },
      currentUserId(){
        return this.$store.state.authStore.dataUser['userId']
      },
      accountsProcessed(){
        let result = this.$store.state.authStore.listAccounts
        result.forEach((element, indexEl) => {
          let sectors = []
          for(let index = 0; index < this.SECTORS.length; index++) {
            if((parseInt(element['sector'], 10) & (1 << index)) > 0){
              sectors.push(this.SECTORS[this.SECTORS.length - 1 - index])
            }          
          }
          result[indexEl]['sector'] = sectors.join('/')
        })
        return result
      },
      headers (){
        var headers = [
          { text: 'Nom', align: 'start', value: 'surname', sortable: true},
          { text: 'Prénom', value: 'firstname', sortable: true },
          { text: "Nom d'utilisateur", value: 'username', sortable: true },
          { text: 'Avatar', value: 'pathAvatar', sortable: true },
          { text: 'Secteur', value: 'sector', sortable: true },
          { text: 'Autorisation', value: 'autorisationName', sortable: true },
          { text: "Modifier compte", value: 'actionsAccount', align: 'center', sortable: false },
        ];
        return headers;
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      }
    },
    methods: {
      deleteAccount(accountId){
        this.$store.dispatch("deleteAccount", {"accountId":accountId});
      },
      modifyAccount(accountId){
        this.$router.push('/accountDetails/'+accountId)
      },
      close () {
        this.dialog = false
      },
      async save () {
        await this.$store.dispatch("registerNewUser", {email: this.email, firstname: this.firstname, surname: this.surname, sector:this.sector, autorisation: this.autorisation}).then(
          this.resetForm()
        )
      },
      resetForm(){
        this.$refs.formAddAccount.reset()
      },
      checkIsRequired: function(value, errorMessage) {
      if (!errorMessage) {
        errorMessage = "Ce champ est nécessaire";
      }
      return !!value || errorMessage;
      },
      checkMinLenght(value, minLength, errorMessage) {
        let valueLength = 0
        if (value !== null) {
          valueLength = value.length
        }
        
        if (!errorMessage) {
          errorMessage = `La longueur minimale est ${minLength}`;
        }
        return (!!valueLength && valueLength >= minLength) || errorMessage;
      },
    },
    mounted() {
      this.$store.dispatch("getRoles")
      this.$store.dispatch("updateListAccounts");
    }
  }


</script>

<style scoped>
.textFieldGrid {
  padding-top: 0px;
}
</style>