<template>
    <v-container>
      <v-row>
        <v-col cols="2" class="my-auto">
          <span class="font-weight-bold">Filtre :</span>
        </v-col>
        <v-col cols="5" class="my-auto">
          <template>
            <v-select
              v-model="type_filter_value"
              :items="types"
              label="Type de remontée"
              dense
              hide-details
              outlined
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle_type"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Tout sélectionner
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col>
      <v-data-iterator
      :items="lifts_filtered(type_filter_value)"
      item-key="lift_id"
      :items-per-page="-1"
      hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="12"
              sm="6"
              md="4"
              lg="4"
            >
              <v-hover v-slot="{ hover }">
                <v-card outlined elevation="2" height=100% :color="liftId == item.lift_id ? 'primary' : hover ? 'grey' : 'blue-grey lighten-5'" class="rounded-xl" @click="$emit('openTab', item)">
                  <div style="display: flex; align-items: center; justify-content: center; height: 35%">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-progress-circular class="mt-5 mb-5" v-bind="attrs" v-on="on" :color="$store.getters.color_state_lift(item.lift_id)['color']" :width="3" :value="($store.getters.color_state_lift(item.lift_id)['percentage_checks'] == 0) ? 100 : $store.getters.color_state_lift(item.lift_id)['percentage_checks']">
                          <v-icon :color="$store.getters.color_state_lift(item.lift_id)['color']">
                            {{ liftTypeIcons[item.lift_type] }}
                          </v-icon>
                        </v-progress-circular> 
                      </template>
                      <span>
                        <v-icon left color="green">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        Ouvert (Heure d'ouverture du registre dépassée)
                        <br>
                      </span>
                      <span>
                        <v-icon left color="orange">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        En prévision d'ouverture (Essais en cours)
                        <br>
                      </span>
                      <span>
                        <v-icon left color="blue">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        Prêt à l'ouverture (Essais terminés, en attente d'une heure d'ouverture)
                        <br>
                      </span>
                      <span>
                        <v-icon left color="red">
                          mdi-checkbox-blank-circle
                        </v-icon>
                        Fermé (Heure de fermeture dépassée ou pas d'essai initié)
                        <br>
                      </span>
                    </v-tooltip>
                    <v-btn
                    icon
                    class="ml-15 mt-5 mb-5"
                    v-if="$store.state.authStore.isResponsableRMOrRegul && isModifyMode"
                    >
                      <v-icon
                      color="red"
                      large
                      @click="$store.dispatch('deleteLift', {lift_id: item.lift_id})">
                        mdi-delete-circle
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-divider></v-divider>
                  <div style="display:flex;  align-items: center; justify-content: center; height: 65%; background-color: white;">
                    <v-card-title class="subheading font-weight-bold justify-center">
                      <span style="word-break: break-word">{{ item.lift_name }}</span>
                    </v-card-title>
                  </div>

                  <!-- <v-divider></v-divider>

                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Ouverture:</v-list-item-content>
                      <v-list-item-content class="text-right">
                        {{ item.opening_time_def.substring(0,5).replace(':','h') }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Fermeture:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ item.closing_time_def.substring(0,5).replace(':','h') }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list> -->
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-col>
    </v-row>
    </v-container>
</template>

<script>
  export default {
    name: "ListLifts",
    data () {
      return {
        type_filter_value: [],
        types:['TC','TSD','TSF','TK','Tapis','Autre'],
        liftTypeIcons: {
          'tc': 'mdi-gondola',
          'tsd': 'mdi-seat-passenger',
          'tsf': 'mdi-seat-passenger',
          'tk': 'mdi-ski-water',
          'tapis': 'mdi-elevation-rise',
          'autre': 'mdi-elevator'
        }
      }
    },
    props: ['isModifyMode'],
    computed: {
      lifts(){
        return this.$store.state.liftsStore.liftsList
      },
      liftId(){
        return this.$store.state.liftsStore.selectedLiftCentral.lift_id
      },
    },
    methods:{
      lifts_filtered(array_selected){
        if (array_selected.length === 0){
          return this.lifts
        }
        else{return this.lifts.filter(lift => array_selected.map(type => type.toLowerCase()).includes(lift.lift_type))}
      },
      toggle_type () {
      this.$nextTick(() => {
        if (this.type_filter_value.length === this.types.length) {
          this.type_filter_value = []
        } else {
          this.type_filter_value = this.types.slice()
        }
      })
    },
    }
  }
</script>

<style scoped>
</style>