<template>
    <v-dialog
        v-model="dialogCreationTask"
        max-width="500px"
    >
        <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            small
            v-bind="attrs"
            v-on="on"
        >
            Créer nouvelle tâche
        </v-btn>
        </template>
        <v-card>
        <v-card-title>
            <span v-if="dataToModify == null" class="text-h5">Ajout nouvelle tâche</span>
            <span v-else class="text-h5">Modification tâche</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog" >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-form ref="formAddTask" v-model="isAddTaskFormValid">
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                            outlined
                            v-model="taskName"
                            label="Nom tâche*"
                            :rules="[checkIsRequired(taskName)]"
                            required
                            hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col
                        v-if="fieldsToAdd.includes('category')"
                        cols="12"
                        >
                            <v-select
                            outlined
                            v-model="category"
                            label="Catégorie"
                            :items="['Station motrice', 'Station retour']"
                            hide-details="auto"
                            :rules="[checkIsRequired(category)]"
                            >
                            </v-select>
                        </v-col>
                        <v-col
                        v-if="!('lift_id' in toAddOnSave)"
                        cols="12"
                        >
                            <v-autocomplete
                            outlined
                            v-model="lift"
                            label="Appareil concerné"
                            return-object
                            :items="$store.state.liftsStore.liftsList"
                            item-value="lift_id"
                            item-text="lift_name"
                            hide-details="auto"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                        v-if="fieldsToAdd.includes('nextAlert') && lift.lift_id"
                        cols="12"
                        >
                            <v-text-field
                            outlined
                            v-model="repetition_default"
                            label="(Optionnel) Par défaut, répétition toutes les X heures d'exploitation"
                            hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col
                        v-if="fieldsToAdd.includes('nextAlert') && lift.lift_id && lift_last_count_hours"
                        cols="12"
                        >
                            <v-text-field
                            outlined
                            :rules="[v => checkIsNumber(v)]"
                            v-model="firstAlert"
                            label="(Optionnel) Prochaine (1ère) alerte dans X heures d'exploitation"
                            hide-details="auto"
                            ></v-text-field>
                            <span v-if="next_alert_exploit_hours_fix">
                                ({{ lift_last_count_hours }}h d'exploitation au {{ lift_date_usage_count_hours }})
                                donc prochaine alerte à {{ next_alert_exploit_hours_fix }}h
                            </span>
                        </v-col>
                        <v-col v-if="fieldsToAdd.includes('nextAlert') && lift.lift_id && !lift_last_count_hours">
                            <span>Pour ajouter une alerte liée au nombre d'heures d'exploitation de l'appareil, il faut avoir au minimum un registre mentionnant son nombre d'heures d'exploitation.</span>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-textarea
                            outlined
                            v-model="taskDescription"
                            label="Description"
                            hide-details="auto"
                            ></v-textarea>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="8"
                        >
                            <v-file-input
                            v-model="imageInput"
                            label="Image"
                            filled
                            prepend-icon="mdi-camera"
                            accept=".png, .jpg, .jpeg"
                            @change="selectImage"
                            ></v-file-input>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="4"
                            align= "center"
                        >
                            <v-img width="100px" v-if="previewImage" :src="previewImage" alt=""></v-img>
                            <v-progress-circular v-if="isLoadingImage" indeterminate></v-progress-circular>
                        </v-col>
                        <v-col
                            cols="12"
                            align= "right"
                        >
                            <v-btn color="error" v-if="newImage != undefined" @click="newImage = undefined; imageDeletion = true; imageInput = null">Supprimer image</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            >
            Annuler
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            :disabled="!isAddTaskFormValid || isLoadingImage"
            @click="saveCreationTask"
            >
            Sauvegarder
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {compressAccurately} from 'image-conversion';
export default {
    name: "DialogCreateTask",
    props: {
        dispatcherOnSave: String,
        toAddOnSave: Object,
        fieldsToAdd: {
            type: Array,
            default(){
                return []
            }
        },
        dataToModify: Object,
    },
    data() {
        return {
            lift_temp: {lift_id: null},
            task_id: null,
            category: 'Station motrice',
            taskName: null,
            taskDescription: null,
            repetition_default: null,
            firstAlert: null,
            isAddTaskFormValid: false,
            dialogCreationTask: false,
            newImage: undefined,
            imageData: null,
            imageDeletion: false,
            imageInput: null,
            isLoadingImage: false,
        }
    },
    computed: {
        lift: {
            get(){
                return this.lift_temp.lift_id == null ? this.$store.state.liftsStore.selectedLiftCentral : this.lift_temp
            },
            set(newValue){
                this.lift_temp = newValue
            }
        } ,
        lift_last_count_hours(){
            return this.lift && 'last_count_hours' in this.lift ? this.lift.last_count_hours : ''
        },
        lift_date_usage_count_hours(){
            return this.lift && 'date_usage_count_hours' in this.lift ? this.lift.date_usage_count_hours : ''
        },
        next_alert_exploit_hours_fix(){
            return this.firstAlert == null ? null : Number(this.lift_last_count_hours) + Number(this.firstAlert)
        },
        previewImage() {
            if (this.newImage != undefined) {
                return this.newImage
            } 
            /*else if (this.oldAvatar != "None" && this.oldAvatar != "") {
                return axiosInstance.defaults.baseURL + "/dataLogged/avatars/"+this.oldAvatar+"/"+this.$store.state.authStore.loginToken
            }*/
            return false
        },
    },
    watch: {
        //We use a watch because dialogCreationTask can be set to false with calling close if we click outside box
        dialogCreationTask(val){
            if(!val){
                this.$emit('closeCreationTask')
                this.lift_temp = {lift_id: null}
                this.category = 'Station motrice'
                this.taskDescription = null
                this.repetition_default = null
                this.firstAlert = null
                this.taskName = null
                this.task_id = null
                this.imageData= null
                this.newImage= undefined
                this.imageDeletion = false
                this.imageInput = null
            }
        },
        dataToModify(val){
            if(val != null){
                this.dialogCreationTask = true
                this.taskName = val['title']
                this.taskDescription = val['description']
                this.task_id = val['task_id']
                if(this.fieldsToAdd.includes('category')){
                    this.category = val['category']
                }
                this.newImage = val['image']
            }
        }
    },
    methods: {
        checkIsRequired(value, errorMessage) {
            if (!errorMessage) {
                errorMessage = "Ce champ est nécessaire";
            }
            return !!value || errorMessage;
        },
        async saveCreationTask() {
            let payload = this.toAddOnSave
            if(!('lift_id' in this.toAddOnSave)){
                payload['lift_id'] = this.lift.lift_id
            }
            payload['title'] = this.taskName
            payload['description'] = this.taskDescription
            payload['task_id'] = this.task_id
            payload['image'] = this.imageData
            payload['imageDeletion'] = this.imageDeletion
            if(this.fieldsToAdd.includes('nextAlert')){
                payload['repetition'] = this.repetition_default
                payload['next_alert'] = this.next_alert_exploit_hours_fix
            }
            if(this.fieldsToAdd.includes('category')){
                payload['category'] = this.category
            }
            await this.$store.dispatch(this.dispatcherOnSave, payload).then(() => {
                this.dialogCreationTask = false
            })
        },
        checkIsNumber(value, errorMessage) {
            if (errorMessage == null) {
                errorMessage = 'La valeur doit être un nombre. Ex: 15'
            }
            return (value >= 0) || (value == null) || errorMessage
        },
        closeDialog(){
            this.dialogCreationTask = false
        },
        selectImage(image){
            this.isLoadingImage = true
            compressAccurately(image,600).then(res=>{
                const imageCompressed = res
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    // convert image file to base64 string
                    this.imageData = reader.result;
                    this.newImage = URL.createObjectURL(imageCompressed)
                }, false);
                this.imageDeletion = false
                reader.readAsDataURL(imageCompressed)
                this.isLoadingImage = false        
            })
        }
    }
}
</script>