<template>
    <v-container>
      <template>
        <v-data-table
          :headers="headers"
          :items="comments"
          :search="search"
          :items-per-page="5"
          :options="{sortBy: ['date_usage'],sortDesc: [true]}"
          class="elevation-1"
          mobile-breakpoint="0"
        >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
            class="ml-4 mr-4"
          ></v-text-field>
        </template>
        <template v-slot:item.conducteur_fullname="props">
          {{ props.item.firstname }} {{ props.item.surname }}
        </template>
        </v-data-table>
      </template>
    </v-container>
</template>

<script>
  export default {
    name: "HistoricCommentsLift",
    data () {
      return {
        search: '',
        headers: [
          {text: 'Date', value: 'date_usage'},
          {text: 'Appareil', value: 'lift_name'},
          {text: 'Commentaire', value: 'comment', width: '16'},
          {text: 'Conducteur', value: 'conducteur_fullname'},
        ],
      }
    },
    computed: {
      comments(){
        return this.$store.state.liftsStore.listCommentsRecords
      }
    },
    mounted(){
      this.$store.dispatch('updateListCommentsRecords')
    },
  }
</script>

<style scoped>
.v-list-item__subtitle {
  white-space: normal;
}
</style>