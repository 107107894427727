<template>
  <div>
    <v-form>
      <v-data-table
        :headers="headers"
        :items="slopes"
        :items-per-page="-1"
        :footer-props="{
        'items-per-page-text':'Nombre de pistes par page',
        'items-per-page-all-text':'Toutes' 
        }"
        :search="search"
        :custom-sort="customSort"
        :loading="isLoading"
        loading-text="Chargement... Merci de patienter"
        class="elevation-1"
        mobile-breakpoint=0
      >
        <template v-slot:top>
          <v-toolbar
          dense>     
            <v-text-field
              v-if="$vuetify.breakpoint.smAndUp"
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              class="m-0 p-0 shrink"
            ></v-text-field>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-divider
              v-if="$vuetify.breakpoint.smAndUp"
              class="mx-3"
              inset
              vertical
            ></v-divider>
            <v-subheader class="pl-0 pr-2" style="white-space: nowrap">
              Date :
            </v-subheader>
            <v-select
            :items='datesSelect()'
            v-model="dateDataChange"
            @change='changeDate'
            required
            hide-details
            style="min-width: 118px; max-width: 118px;"
            ></v-select>
            <v-spacer v-if="!$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-divider
              class="mx-sm-3"
              inset
              vertical
            ></v-divider>
            <v-btn
            icon
            @click='refreshSlopes'>
              <v-icon
              large
              color="blue">
                mdi-refresh-circle
              </v-icon>
            </v-btn>
            <v-divider
              v-if='$store.state.authStore.isResponsablePistesOrRegul && $vuetify.breakpoint.smAndUp'
              class="mx-3"
              inset
              vertical
            ></v-divider>
            <dialog-create-slope
            v-if='$store.state.authStore.isResponsablePistesOrRegul && $vuetify.breakpoint.smAndUp' :dataToModify="dataToModify" @closeCreationSlope="dataToModify = null">
            </dialog-create-slope>
          </v-toolbar>
          <v-toolbar
          v-if="!$vuetify.breakpoint.smAndUp"
          dense>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              class="mr-3 shrink"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-divider
              v-if='$store.state.authStore.isResponsablePistesOrRegul'
              class="mr-3"
              inset
              vertical
            ></v-divider>
            <dialog-create-slope
            v-if='$store.state.authStore.isResponsablePistesOrRegul'>
            </dialog-create-slope>
          </v-toolbar>
        </template>
        <template v-slot:item.slopeName="{ item }">
          <div class="d-flex flex-column align-center" style="max-width: 95px;">
            <v-chip
              :color="item.color"
              :text-color="item.color == 'yellow' ? 'black' : 'white'"
              dark
            >
              {{ item.slopeName }}
            </v-chip>
            <td>{{ item.area }}</td>
          </div>
        </template>
        <template v-slot:item.openingTime="props">
          <v-text-field
            v-model="props.item.openingTime"
            :append-icon="props.item.openingTimeChanged ? 'mdi-checkbox-marked-circle' : ''"
            type="time"
            @input="props.item.openingTimeChanged = true"
            @click:append="modifyOpeningTime(props.item.openingTime, props.item['slopeId'])"
          ></v-text-field>
        </template>
        <template v-slot:item.closingTime="props">
          <v-text-field
            v-model="props.item.closingTime"
            :append-icon="props.item.closingTimeChanged ? 'mdi-checkbox-marked-circle' : ''"
            type="time"
            @input="props.item.closingTimeChanged = true"
            @click:append="modifyClosingTime(props.item.closingTime, props.item['slopeId'])"
          ></v-text-field>
        </template>
        <template v-slot:item.opener="props">
          <div class="d-md-flex align-center my-2">
            <v-btn
              :class="classOpener(props.item['opener'])"
              @click="fillOpener(props.item['slopeId'])"
            >MOI</v-btn>
            <v-autocomplete
              hide-details
              :items="listPatrollers"
              item-text="name"
              v-model="props.item.opener"
              @change='$store.dispatch("actionModifySlopeUsage", {"propToModify":"opener", "slopeId":props.item["slopeId"], "opener": $event, "date": dateData});'
              class="pt-0 mt-0 ml-1"
            >
              <template slot="selection" slot-scope="data">
                  <div style="white-space: nowrap;" :class="($vuetify.breakpoint.smAndDown) ? 'littleBoxNames' : ''">
                    <v-avatar v-if="data.item.pathAvatar && enableAvatar" height="24px" width="24px" min-width="24px" class='ml-0'>
                      <v-img :src='getAvatar(data.item.pathAvatar)'></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </div>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="enableAvatar" height="24px" width="24px" min-width="24px">
                  <img v-if="data.item.pathAvatar" :src="getAvatar(data.item.pathAvatar)" />
                </v-list-item-avatar>
                <v-list-item-content :class="($vuetify.breakpoint.smAndDown) ? 'littleBoxNames' : ''">
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </template>
        <template v-slot:item.closer="props">
          <div class="d-md-flex align-center">
            <v-btn
              :class="classCloser(props.item['closer'])"
              @click="fillCloser(props.item['slopeId'])"
            >MOI</v-btn>
            <v-autocomplete
              hide-details
              :items="listPatrollers"
              item-text="name"
              v-model="props.item.closer"
              @change='$store.dispatch("actionModifySlopeUsage", {"propToModify":"closer", "slopeId":props.item["slopeId"], "closer": $event, "date": dateData});'
              class="pt-0 mt-0 ml-1"
            >
              <template slot="selection" slot-scope="data">
                <div style="white-space: nowrap;" :class="($vuetify.breakpoint.smAndDown) ? 'littleBoxNames' : ''">
                  <v-avatar v-if="data.item.pathAvatar && enableAvatar" height="24px" width="24px" min-width="24px" class='ml-0'>
                    <v-img :src='getAvatar(data.item.pathAvatar)'></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </div>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-avatar v-if="enableAvatar" height="24px" width="24px" min-width="24px">
                  <img v-if="data.item.pathAvatar" :src="getAvatar(data.item.pathAvatar)" />
                </v-list-item-avatar>
                <v-list-item-content :class="($vuetify.breakpoint.smAndDown) ? 'littleBoxNames' : ''">
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </template>
        <template v-slot:item.closed="props">
          <v-btn
          icon>
            <v-icon
            color="red"
            large
            @click="setClosed(props.item.slopeId)">
              mdi-close-box
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.comment="props">
          <div class="d-md-flex align-center" style="width: 300px;">
            <v-text-field
            v-model="props.item.comment"
            :append-icon="props.item.commentChanged ? 'mdi-checkbox-marked-circle' : ''"
            filled
            clear-icon="mdi-close-circle"
            clearable
            hide-details
            label="Commentaire"
            type="text"
            @input="props.item.commentChanged = true"
            @click:append='$store.dispatch("actionModifySlopeUsage", {"propToModify":"comment", "slopeId":props.item["slopeId"], "comment": props.item.comment, "date": dateData});'
            @click:clear='props.item.commentChanged = false; props.item.comment = ""; $store.dispatch("actionModifySlopeUsage", {"propToModify":"comment", "slopeId":props.item["slopeId"], "comment": "", "date": dateData});'
            ></v-text-field>
          </div>
        </template>
        <template v-slot:item.actionsSlope="props">
          <v-btn icon>
            <v-icon
            color="blue"
            large
            @click="modifySlope(props.item)">
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
          icon>
            <v-icon
            color="red"
            large
            @click="deleteSlope(props.item.slopeId)">
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-form>
  </div>
</template>

<script>
import axiosInstance from '@/plugins/connectionBuilder.js'
import DialogCreateSlope from './DialogCreateSlope.vue'
export default {
  components: { DialogCreateSlope },
  name: "TableSlopes",
  data () {
    return {
      sortOrderSectors: ["Corbier", "SJA", "St Sorlin", "Corbier-St Sorlin"],
      sortOrderColor: ["green", "blue", "red", "black", "yellow"],
      dateDataChange: ('0' + new Date().getDate()).slice(-2)+'/'+('0' + (new Date().getMonth()+1)).slice(-2)+'/'+new Date().getFullYear(),
      search: '',
      dataToModify: null,
    }        
  },
  computed: {
    isLoading(){
      return this.$store.state.slopesStore.slopesIsLoading
    },
    headers(){
      var head = [
        { text: 'Pistes', align: 'center', value: 'slopeName' },
        { text: 'Ouvreur', value: 'opener', align: 'center', sortable: false },
        { text: 'Nom fermeture', value: 'closer', align: 'center', sortable: false },
        { text: 'Fermée ?', value: 'closed', align: 'center', sortable: false, width: '58px' },
        { text: 'Heure Ouverture', value: 'openingTime', align: 'center', sortable: false },
        { text: 'Heure Fermeture', value: 'closingTime', align: 'center', sortable: false },
        { text: "Commentaire", value: 'comment', align: 'center', sortable: false }
      ]
      if(this.$store.state.authStore.isResponsablePistesOrRegul){
        head.push({ text: "Modifier piste", value: 'actionsSlope', align: 'center', sortable: false })
      }
      return head
    },
    slopes (){
      return this.$store.state.slopesStore.slopesMetaData;
    },
    
    dateData(){
      return this.$store.state.slopesStore.dateSlopes;
    },
    listPatrollers(){
      var arr = this.$store.state.authStore.listPatrollers;
      arr.unshift({name:"", pathAvatar:""});
      arr.push({name:"Fermé", pathAvatar:""})
      return arr;
    },
    enableAvatar(){
      return true
      /*
      if(this.$store.state.authStore.isUserAdminOrRegul){
        return false;
      } else{
        return true;
      }
      */
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    refreshSlopes() {
      this.$store.commit('setSlopesMetaData', []);
      this.$store.dispatch("updateSlopesMetaData", this.dateData);
    },
    getAvatar(pathAvatar) {
      if(pathAvatar != null){
        return axiosInstance.defaults.baseURL + "/dataLogged/avatars/" + pathAvatar + "/" + this.$store.state.authStore.loginToken
      } else {
        return
      }
    },
    datesSelect(){
      const current = new Date();
      const yesterday = new Date(new Date().setDate(new Date().getDate()-1));
      return [('0' + current.getDate()).slice(-2)+'/'+('0' + (current.getMonth()+1)).slice(-2)+'/'+current.getFullYear(), ('0' + yesterday.getDate()).slice(-2)+'/'+('0' + (yesterday.getMonth()+1)).slice(-2)+'/'+yesterday.getFullYear()];
    },
    fillOpener(slopeId) {
    this.$store.dispatch("actionModifySlopeUsage", {"propToModify":"opener", "slopeId":slopeId, "opener":this.$store.state.authStore.dataUser["surname"], "date": this.dateData});
    },
    fillCloser(slopeId) {
      this.$store.dispatch("actionModifySlopeUsage", {"propToModify":"closer", "slopeId":slopeId, "closer":this.$store.state.authStore.dataUser["surname"], "date": this.dateData});
    },
    modifyOpeningTime(newTime, slopeId) {
      this.$store.dispatch("actionModifySlopeUsage", {"propToModify":"openingTime", "slopeId":slopeId, "openingTime": newTime, "date": this.dateData});
    },
    modifyClosingTime(newTime, slopeId) {
      this.$store.dispatch("actionModifySlopeUsage", {"propToModify":"closingTime", "slopeId":slopeId, "closingTime": newTime, "date": this.dateData});
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (!isDesc[0]) {
          var res = this.sortOrderSectors.indexOf(a.area) - this.sortOrderSectors.indexOf(b.area)
          if (res == 0){
            res = this.sortOrderColor.indexOf(a.color) - this.sortOrderColor.indexOf(b.color);
            if (res == 0){
              res = a.slopeName.localeCompare(b.slopeName);
            }
          }
          return res;
        } else {
          var res2 = this.sortOrderSectors.indexOf(b.area) - this.sortOrderSectors.indexOf(a.area);
          if (res2 == 0){
            res2 = this.sortOrderColor.indexOf(b.color) - this.sortOrderColor.indexOf(a.color);
            if (res2 == 0){
              res2 = b.slopeName.localeCompare(a.slopeName);
            }
          }
          return res2;
        }
      });
      return items;
    },
    classOpener(opener){
      if(opener == this.$store.state.authStore.dataUser["surname"]){
        return "mr-lg-5 light-green lighten-2"
      } else {
        return "mr-lg-5 light-green lighten-5"
      }
    },
    classCloser(closer){
      if(closer == this.$store.state.authStore.dataUser["surname"]){
        return "mr-lg-5 red lighten-2"
      } else {
        return "mr-lg-5 red lighten-5"
      }
    },
    setClosed(slopeId){
      this.$store.dispatch("actionModifySlopeUsage", [{"propToModify":"opener", "slopeId":slopeId, "opener":'Fermé', "date": this.dateData}, {"propToModify":"closer", "slopeId":slopeId, "closer":'Fermé', "date": this.dateData}]);
    },
    changeDate(newDate){
      this.$store.commit('setSlopesMetaData', []);
      this.$store.dispatch("updateSlopesMetaData", newDate);
    },
    deleteSlope(slopeId){
      this.$store.dispatch("deleteSlope", {"slopeId":slopeId, "currentDateDisplayed":this.dateData});
    },
    modifySlope(slope){
      this.dataToModify = slope
    },
  },
  mounted() {
    this.$store.commit('setSlopesMetaData', []);
    this.$store.dispatch("updateSlopesMetaData", this.dateDataChange);
    this.$store.dispatch("updateListPatrollers");
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.text-center {
  padding: 0 2px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th.text-center {
  padding: 0 2px;
}

.select { width: 60px !important}

.littleBoxNames {
  overflow: hidden;
  max-width: 70px;
}
</style>