<!-- 
frontend\src\views\AccountDetails.vue
-->
<template>
<v-container grid-list-xs>
  <v-layout row wrap justify-center align-center>
    <v-flex xs12 md8 lg8 class="mb-2" v-if="$store.state.authStore.deferredPrompt && $store.state.authStore.dataUser['userId'] == userId">
      <v-card>
        <v-card-title primary-title>
          <h2>Installer l'application web</h2>
        </v-card-title>
        <v-card-text>
          Afin d'accéder plus facilement à Digiski, installez l'application sur votre téléphone.
          Vous la retrouverez alors sur votre écran d'accueil.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="install" color="primary">Installer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex xs12 md8 lg8 class="mb-2">
      <v-card>
        <v-card-title primary-title>
          <h2>Mes informations</h2>
        </v-card-title>
        <v-card-text>
          <v-form rounded v-model="isInfosFormValid">
            <v-text-field
            name="email"
            label="Nom d'utilisateur/Email*"
            id="emailToModify"
            v-model="email"
            prepend-icon="mdi-account-circle"
            required
            :disabled="!$store.state.authStore.isUserAdminOrRegul"
            :rules="[checkIsRequired(email), checkMinLength(email.length,3)]"
            type="email"
            ></v-text-field>

            <v-text-field
            name="firstname"
            label="Prénom*"
            id="firstnameToModify"
            v-model="firstname"
            :rules="[checkIsRequired(firstname)]"
            ></v-text-field>

            <v-text-field
            name="surname"
            label="Nom de famille*"
            id="surnameToModify"
            v-model="surname"
            :rules="[checkIsRequired(surname)]"
            ></v-text-field>

            <v-combobox
              v-model="sectorRaw"
              :items="sectorsCombobox"
              item-text="text"
              item-value="value"
              name="sector"
              label="Secteur*"
              id="sectorToModify"
              :rules="[sectorRaw.length != 0]"
              clearable
              multiple
              :disabled="!$store.state.authStore.isUserAdminOrRegul"
              small-chips
            ></v-combobox>

            <v-select
              :items='autorisationSelect'
              item-text="text"
              item-value="value"
              v-model="autorisationValue"
              label="Autorisation*"
              required
              multiple
              :rules="[checkIsRequired(autorisationValue)]"
              :disabled="!$store.state.authStore.isUserAdminOrRegul || userId == $store.state.authStore.dataUser['userId']"
            ></v-select>

            <v-btn
              outlined
              elevation="9"
              @click="$store.dispatch('readForfait').then((ski_pass_number) => afterReadForfait(ski_pass_number))"
            >Lire mon forfait</v-btn>
            <v-text-field
            name="ski_pass_number_decoded"
            label="N° de forfait"
            id="ski_pass_numberToModify"
            v-model="ski_pass_number_decoded"
            ></v-text-field>

            <div class="text-center">
              <v-progress-circular indeterminate color="primary" v-if="isProcessingInfosModif"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            elevation="9"
            :disabled="!isInfosFormValid || isProcessingInfosModif"
            @click="modifyInformations({userId: userId, email:email, firstname:firstname, surname:surname, sector:sector, autorisation:autorisationValue, ski_pass_number:ski_pass_number})"
          >
            <v-icon left>mdi-account-edit</v-icon>Modifier les informations
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md8 lg8 class="mb-2">
      <v-card>
        <v-card-title primary-title>
          <h2>Modifier mon avatar</h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                <v-file-input
                  label="Changer Avatar"
                  filled
                  prepend-icon="mdi-camera"
                  accept=".png, .jpg, .jpeg"
                  @change="selectImage"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                align= "center"
              >
                <v-img width="100px" v-if="previewImage" :src="previewImage" alt=""></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="oldAvatar != 'None' && oldAvatar != ''"
            color="error"
            elevation="9"
            :disabled="isProcessingAvatarModif"
            @click="onModifyAvatar('delete', null)"
          >
            <v-icon left>mdi-account-edit</v-icon>Supprimer l'avatar
          </v-btn>
          <v-btn
            outlined
            elevation="9"
            :disabled="isProcessingAvatarModif"
            @click="onModifyAvatar('add', newAvatarUploaded)"
          >
            <v-icon left>mdi-account-edit</v-icon>Valider nouvel avatar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 md8 lg8>
      <v-card v-if="!$store.state.authStore.isUserAdminOrRegul || userId == $store.state.authStore.dataUser['userId']">
        <v-card-title primary-title>
          <h2>Modifier le mot de passe</h2>
        </v-card-title>
        <v-card-text>
          <v-form rounded v-model="isPasswordFormValid">
            <v-text-field
              name="passwordOld"
              label="Mot de passe actuel*"
              id="passwordOld"
              v-model="passwordOld"
              :type="getPasswordFieldType()"
              prepend-icon="mdi-lock"
              :append-icon="getShowPasswordApendIcon()"
              @click:append="toggleShowPassword()"
              :rules="[checkIsRequired(passwordOld)]"
              required
            ></v-text-field>
            <v-text-field
              name="password"
              label="Nouveau mot de passe*"
              id="passwordToModify"
              v-model="passwordNew"
              :type="getPasswordFieldType()"
              prepend-icon="mdi-lock-reset"
              :append-icon="getShowPasswordApendIcon()"
              @click:append="toggleShowPassword()"
              counter="50"
              required
              :rules="[checkIsRequired(passwordNew), checkMinLength(passwordNew.length,8)]"
              loading
              >
              <template v-slot:progress>
                  <v-progress-linear :value="progress(passwordNew, 8)" :color="color(passwordNew, 8)" absolute height="7"></v-progress-linear>
              </template>
            </v-text-field>

            <v-text-field
              name="passwordVerif"
              label="Répétez nouveau mot de passe*"
              id="passwordToModifyVerif"
              v-model="passwordNewVerif"
              :type="getPasswordFieldType()"
              prepend-icon="mdi-lock-reset"
              :append-icon="getShowPasswordApendIcon()"
              @click:append="toggleShowPassword()"
              required
              :rules="[checkIsRequired(passwordNewVerif), verifSamePassword(passwordNewVerif, passwordNew)]"
              >
            </v-text-field>

            <div class="text-center">
              <v-progress-circular indeterminate color="primary" v-if="isProcessingPwdModif"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            elevation="9"
            :disabled="!isPasswordFormValid || isProcessingPwdModif"
            @click="modifyPassword({userId: userId, passwordOld:passwordOld, passwordNew:passwordNew})"
          >
            <v-icon left>mdi-account-edit</v-icon>Modifier le mot de passe
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title primary-title>
          <h2>Modifier le mot de passe</h2>
        </v-card-title>
        <v-card-text>
          <v-form rounded>
            <v-text-field
              name="password"
              label="Nouveau mot de passe*"
              id="passwordToModify"
              v-model="passwordNew"
              prepend-icon="mdi-lock-reset"
              disabled
              required
              >
              <template v-slot:progress>
                  <v-progress-linear :value="progress(passwordNew, 8)" :color="color(passwordNew, 8)" absolute height="7"></v-progress-linear>
              </template>
            </v-text-field>
            <div class="text-center">
              <v-progress-circular indeterminate color="primary" v-if="isProcessingPwdModif"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            elevation="9"
            :disabled="isProcessingPwdModif"
            @click="generatePassword"
          >
            <v-icon left>mdi-account-edit</v-icon>Générer nouveau mot de passe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import axiosInstance from '@/plugins/connectionBuilder.js'
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AccountDetails",
  data: function() {
    return {
      sectorsCombobox: [{text: "Corbier", value: 0}, {text: "SJA", value: 1}, {text: "St-Sorlin", value: 2}],
      isInfosFormValid: false,
      isPasswordFormValid: false,
      userId: this.$route.params.id == 'me' ? this.$store.state.authStore.dataUser["userId"] : this.$route.params.id,
      email: "",
      firstname: "",
      surname: "",
      ski_pass_number: "",
      oldAvatar: "",
      sector: 0,
      showPassword: false,
      passwordNew: "",
      passwordNewVerif: "",
      passwordOld: "",
      newAvatarUploaded: undefined,
      autorisationValue: [],
    };
  },
  computed: {
    autorisationSelect(){
      return this.$store.state.authStore.rolesAvailable
    },
    sectorRaw:{
      get(){
        return parseInt(this.sector, 10).toString(2).split("").map((x, index) => x == 0 ? -1:this.sectorsCombobox[parseInt(this.sector, 10).toString(2).split("").length -1 - index]).filter(el => el !== -1)
      },
      set(newValue){
        if(newValue.length == 0) {
          this.sector = 0
        } else {
          this.sector = newValue.reduce((accumulator, b) => accumulator + 2**b.value, 0)
        }
      }
    },
    ...mapGetters([
      "isProcessingPwdModif",
      "isProcessingInfosModif",
      "isProcessingAvatarModif"
    ]),
    previewImage() {
      if (this.newAvatarUploaded != undefined) {
        return this.newAvatarUploaded
      } else if (this.oldAvatar != null && this.oldAvatar != "") {
        return axiosInstance.defaults.baseURL + "/dataLogged/avatars/"+this.oldAvatar+"/"+this.$store.state.authStore.loginToken
      }
      return false
    },
    ski_pass_number_decoded(){
      if (this.ski_pass_number == null){
        return null
      }
      const hex = this.ski_pass_number.split(':').reverse().join('')
      for (var inputValues = [], j = 0; j < hex.length; j++) {
        inputValues[j] = parseInt(hex.charAt(j), 16);
      }
      var outputValues = [] //output array, little-endian/lsd order
      var remainder
      var len = inputValues.length
      var pos = 0
      var i
      while (pos < len) { //while digits left in input array
        remainder = 0; //set remainder to 0
        for (i = pos; i < len; i++) {
          //long integer division of input values divided by output base
          //remainder is added to output array
          remainder = inputValues[i] + remainder * 16;
          inputValues[i] = Math.floor(remainder / 10);
          remainder -= inputValues[i] * 10;
          if (inputValues[i] == 0 && i == pos) {
            pos++;
          }
        }
        outputValues.push(remainder);
      }
      outputValues.reverse(); //transform to big-endian/msd order
      for (var bigint = '', k = 0; k < outputValues.length; k++) {
        // Add space every 4 characters
        if(k % 4 === 0){
          bigint += ' '
        }
        bigint += outputValues[k].toString(10);
      }
      return bigint
    }
  },
  methods: {
    afterReadForfait(ski_pass_number){
      this.ski_pass_number = ski_pass_number
    },
    getPasswordFieldType: function() {
      if (this.showPassword) {
        return "text";
      } else {
        return "password";
      }
    },
    toggleShowPassword: function() {
      this.showPassword = !this.showPassword;
    },
    getShowPasswordApendIcon: function() {
      if (this.showPassword) {
        return "mdi-eye";
      } else {
        return "mdi-eye-off";
      }
    },
    checkIsRequired: function(value, errorMessage) {
      if (!errorMessage) {
        errorMessage = "Ce champ est nécessaire";
      }
      return !!value || errorMessage;
    },
    verifSamePassword: function(password1, password2, errorMessage) {
      if (!errorMessage) {
        errorMessage = "Le nouveau mot de passe n'est pas identique";
      }
      return password1 == password2 || errorMessage;
    },
    checkMinLength(valueLenght, minLength, errorMessage) {
      if (!errorMessage) {
        errorMessage = `La longueur minimale est ${minLength}`;
      }
      return (!!valueLenght && valueLenght >= minLength) || errorMessage;
    },
    progress(text, minLength) {
      return Math.min(100, (text.length / minLength) * 100);
    },
    color(text, minLength) {
      let selectedColorIndex = 0;
      if (this.progress(text, minLength) == 0) {
        selectedColorIndex = 3
      } else if (this.progress(text, minLength) < 40) {
        selectedColorIndex = 0;
      } else if (
        this.progress(text, minLength) > 40 &&
        this.progress(text, minLength) < 100
      ) {
        selectedColorIndex = 1;
      } else {
        selectedColorIndex = 2;
      }
      return ["error", "warning", "success", "grey"][selectedColorIndex];
    },
    validateEmail(email) {
      var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      return (
        re.test(String(email).toLowerCase()) ||
        "Oops, this doesn't looks like rigth, can you check please?"
      );
    },
    selectImage(image) {
      const that = this
      const img = new Image()
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onloadend = function(e) {
        img.src = e.target.result
        img.onload = function() {
          var canvas = document.createElement('canvas');
          // The size will be the square of this
          const maxWidth = 80;
          var ctx = canvas.getContext('2d');
          //Resize the image to take the center 
          const mWidth = Math.min(maxWidth, img.width, img.height)
          canvas.width = mWidth;
          canvas.height = mWidth;
          if (img.height >= img.width) {
            ctx.drawImage(img, 0, img.height/2 - img.width/2, img.width, img.width, 0, 0, mWidth, mWidth)
          } else {
            ctx.drawImage(img, img.width/2 - img.height/2, 0, img.height, img.height, 0, 0, mWidth, mWidth)
          }
          const imageData = canvas.toDataURL("image/jpg")
          that.newAvatarUploaded = imageData
        }
      }
    },
    updateData() {
      this.getAccount({'controllerReference': this, 'accountId': this.userId}).then(function(response){
        response.controller.email = response.data['username']
        response.controller.firstname = response.data['firstname']
        response.controller.surname = response.data['surname']
        response.controller.oldAvatar = response.data['pathAvatar']
        response.controller.sector = response.data['sector']
        response.controller.autorisationValue = response.data['autorisation']
        response.controller.ski_pass_number = response.data['ski_pass_number']
      })
    },
    generatePassword() {
      this.generateNewPassword({'controllerReference': this, 'userId': this.userId}).then(function(response){
        response.controller.passwordNew = response.data['password']
      })
    },
    async onModifyAvatar(avatarAction, avatarPath) {
      await this.modifyAvatar({avatarAction: avatarAction, avatar: avatarPath, userId: this.userId})
      this.updateData()
    },
    async install() {
      this.$store.state.authStore.deferredPrompt.prompt();
    },
    ...mapActions(["modifyInformations",
    "modifyPassword",
    "generateNewPassword",
    "modifyAvatar",
    "getAccount"
    ]),
  },
  mounted() {
    this.$store.dispatch("getRoles")
    this.updateData()
  },
  beforeRouteUpdate(to, from, next) {
    this.userId = to.params.id == 'me' ? this.$store.state.authStore.dataUser["userId"] : to.params.id
    this.updateData()
    next()
  },
};
</script>