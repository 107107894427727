<template>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="tasks"
        item-key="name"
        sort-by="name"
        :search="search"
        class="elevation-1 pt-1"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <dialog-create-task dispatcherOnSave= "addTaskMaintenance" :toAddOnSave="{user_created: $store.state.authStore.dataUser['userId'], record_created: null}" :fieldsToAdd="['nextAlert']"></dialog-create-task>
          <v-divider class="mt-1"></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
            class="ml-4 mr-4"
          ></v-text-field>
        </template>
        <template v-slot:item.checkbox="props">
          <v-dialog
          v-model="dialogCheckbox[props.item.task_id]"
          max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                :color="props.item.is_done ? 'success' : (props.item.date_historic == null ? 'red' : 'blue')"
                v-bind="attrs"
                v-on="props.item.is_done ? '': on"
              >
                <v-icon >
                  mdi-check-circle
                </v-icon>
              </v-btn>
            </template>
            <v-card>
            <v-card-title>
                <span class="text-h5">Notifier tâche effectuée</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogCheckbox[props.item.task_id] = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form ref="formAddTaskDone" v-model="isAddTaskDoneFormValid">
              <v-radio-group v-model="props.item.after_task_choice">
                <v-radio
                  :key="props.item.task_id + 'close'"
                  label="Fermer la tâche"
                  value="close"
                ></v-radio>
                <v-radio
                  v-if="!props.item.record_created"
                  :key="props.item.task_id + 'open'"
                  label="Garder la tâche ouverte sans rappel"
                  value="open"
                ></v-radio>
                <v-radio
                  v-if="!props.item.record_created && props.item.last_count_hours"
                  :key="props.item.task_id + 'openAlertIn'"
                  label="Garder la tâche ouverte avec rappel dans :"
                  value="openAlertIn"
                ></v-radio>
                <v-text-field
                  v-if="!props.item.record_created && props.item.last_count_hours"
                  centered label="Nombre d'heures d'exploitation de l'appareil"
                  v-model="props.item.repetition_default"
                  :rules="[v => checkIsNumber(v)]"
                  prepend-inner-icon="mdi-wrench-clock"
                  outlined
                  dense
                  hide-details="auto"
                  @click="props.item.after_task_choice = 'openAlertIn'"
                ></v-text-field>
                <span v-if="props.item.next_alert_exploit_hours_fix">
                  ({{props.item.last_count_hours}}h d'exploitation au {{props.item.date_usage_count_hours}})
                  donc prochaine alerte à {{ props.item.next_alert_exploit_hours_fix }}h
                </span>
                <span v-if="!props.item.record_created && !props.item.last_count_hours">Pour ajouter une alerte liée au nombre d'heures d'exploitation de l'appareil, il faut avoir au minimum un registre mentionnant son nombre d'heures d'exploitation.</span>
                <!--
                <v-radio
                  key="openAlertOn"
                  label="Garder la tâche ouverte avec rappel le :"
                  value="openAlertOn"
                ></v-radio>
                <v-radio
                  key="openAlertInUse"
                  label="Garder la tâche ouverte avec rappel dans :"
                  value="openAlertInUse"
                ></v-radio>
                -->
              </v-radio-group>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="blue darken-1"
              text
              @click="closeCheckbox(props.item.task_id)"
              >
              Annuler
              </v-btn>
              <v-btn
              color="blue darken-1"
              text
              :disabled="!isAddTaskDoneFormValid"
              @click="saveCheckbox(props.item)"
              >
              Sauvegarder
              </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.infos="props">
          <dialog-infos-task :task="props.item"></dialog-infos-task>
        </template>
        <template v-slot:item.next_alert_exploitation_hours="props">
          <div v-if="props.item.next_alert_exploitation_hours != null">
            A {{props.item.next_alert_exploitation_hours}}h d'exploit. (dans {{props.item.next_alert_in_exploit_hours}}h)
          </div>
        </template>
        <template v-slot:item.date_historic="props">
          <v-dialog
          v-model="dialogHistoric[props.item.task_id]"
          max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="text-truncate" style="max-width: 160px">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('updateListTaskMaintenanceHistory', {task_id: props.item.task_id})"
                >
                  <v-icon >
                    mdi-history
                  </v-icon>
                </v-btn>
                {{ props.item.date_historic }}
              </div>
            </template>
            <v-card>
            <v-card-title>
                <span class="text-h5">Historique de la tâche</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogHistoric[props.item.task_id] = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headersHistoric"
                :items="history"
                class="elevation-1 mt-1"
                mobile-breakpoint="0"
              >
                <template v-slot:item.user="props">
                  {{ props.item.firstname }} {{ props.item.surname }}
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="blue darken-1"
                text
                @click="$set(dialogHistoric, props.item.task_id, false)"
                >
                Fermer
                </v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.origin="props">
          <span v-if="props.item.record_created">Registre</span>
          <span v-else>Central</span>
        </template>
        <template v-slot:item.actionsTask="props">
          <v-btn
          icon>
            <v-icon
            color="red"
            large
            @click="$store.dispatch('deleteTaskMaintenance', {task_id: props.item.task_id, lift_id: props.item.lift_id})">
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
</template>

<script>
import DialogCreateTask from '@/components/DialogCreateTask'
import DialogInfosTask from '@/components/DialogInfosTask'
  export default {
    name: "TasksMaintenanceLift",
    components: {
      DialogCreateTask,
      DialogInfosTask
    },
    data () {
      return {
        search:'',
        headersHistoric: [
          {text: 'Date', value: 'datetime_done'},
          {text: 'Responsable', value: 'user'},
        ],
        dialogCreationTask: false,
        dialogCheckbox: {},
        dialogDescription: {},
        dialogHistoric: {},
        isAddTaskFormValid: false,
        isAddTaskDoneFormValid: false,
        taskName: null,
        nextAlert: null,
        taskDescription: null,
      }
    },
    computed: {
      headers(){
        let headers = [
          {text: 'Effectuée ?', value: 'checkbox'},
          {text: 'Appareil', value: 'lift_name'},
          {text: 'Tâche', value: 'title', cellClass: 'pr-0', class: 'pr-0'},
          {text: '', value: 'infos', cellClass: 'px-0', class: 'px-0', sortable: false},
          {text: 'Origine', align: 'center', value: 'origin'},
          {text: 'Date ajout', align: 'center', value: 'time_creation'},
          {text: 'Prochaine alerte', value: 'next_alert_exploitation_hours'},
          {text: 'Historique', value: 'date_historic'},
        ]
        if(this.$store.state.authStore.isResponsableRM){
          headers.push({ text: "Modifier tâche", value: 'actionsTask', sortable: false })
        }
        return headers
      },
      liftId(){
        return this.$store.state.liftsStore.selectedLiftCentral.lift_id
      },
      tasks(){
        let tasks = this.$store.state.liftsStore.listTasksMaintenance
        tasks.forEach(el => el['after_task_choice'] = (el['repetition_default'] == null | el['repetition_default'] == '') ? 'close' : 'openAlertIn')
        tasks.forEach(el => el['next_alert_exploit_hours_fix'] = el.repetition_default != null ? Number(el.last_count_hours) + Number(el.repetition_default) : null)
        tasks.forEach(el => el['next_alert_in_exploit_hours'] = Number(el.next_alert_exploitation_hours) - Number(el.last_count_hours))
        tasks.forEach(el => el['time_creation'] = new Date(el['time_creation']).toLocaleString('fr-FR'))
        return tasks
      },
      history(){
        let history = this.$store.state.liftsStore.listTaskMaintenanceHistory
        history.forEach(el => el['datetime_done'] = new Date(el['datetime_done']).toLocaleString('fr-FR'))
        return history
      }
    },
    methods: {
        closeCheckbox(itemId) {
          this.$set(this.dialogCheckbox, itemId, false)
        },
        async saveCheckbox(item) {
            await this.$store.dispatch('addDoneTaskMaintenance', {task_id: item.task_id, user_closed: this.$store.state.authStore.dataUser['userId'], close_task: item.after_task_choice == 'close', next_alert: item.next_alert_exploit_hours_fix}).then(() => {
                this.closeCheckbox(item.task_id)
            })
        },
        checkIsNumber(value, errorMessage) {
          if (errorMessage == null) {
            errorMessage = 'La valeur doit être un nombre. Ex: 15'
          }
          return (value >= 0) || (value == null) || errorMessage
        },
    },
    mounted(){
      this.$store.dispatch('updateListTasksMaintenance')
    }
  }
</script>