<template>
  <v-data-table
  :headers="headers"
  :items="listRecords"
  :options="{sortBy: ['date_usage']}"
  :items-per-page="15"
  :search="search"
  :loading="isLoading"
  :custom-sort="customSort"
  :multi-sort="true"
  loading-text="Chargement... Merci de patienter"
  :footer-props="{
  'items-per-page-text':'Nombre de lignes par page',
  'items-per-page-all-text':'Toutes'
  }"
  class="elevation-1"
  mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          class="m-0 p-0"
        ></v-text-field>
      </v-card-title>
    </template>
    <template v-slot:item.numericRegistre="props">
      <div class="d-flex align-center">
          <v-btn
          class="mr-5"
          @click="$store.state.liftsStore.liftRecordProps['date'] = props.item['date_usage']; $store.state.liftsStore.liftRecordProps['lift_id'] = Number(props.item['lift_id']); $router.push('/liftRecord')"
          >Registre Numérique</v-btn>
      </div>
    </template>
    <template v-slot:item.download="props">
        <div class="d-flex align-center">
            <v-btn
            class="mr-5"
            @click="$store.dispatch('downloadPdfRecord', {'date_usage': props.item['date_usage'], 'lift_id': props.item['lift_id'], 'lift_name': props.item['lift_name']})"
            >TELECHARGER</v-btn>
        </div>
    </template>
    <template v-slot:item.final_signature_chef_exploitation="props">
        <div class="d-flex align-center">
            <v-checkbox
              v-model="props.item.final_signature_chef_exploitation"
              @change="signRecord($event, props.item.lift_id, props.item.date_usage)"
              :disabled=isSignable
            ></v-checkbox>
        </div>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "TableHistoricRegistresLift",
    data () {
      return {
        search: '',
        headers: [
          { text: 'Date', value: 'date_usage'},
          { text: 'Appareil', value: 'lift_name'},
          { text: 'Voir le registre numérique', value: 'numericRegistre', sortable: false },
          { text: 'Télécharger pdf', value: 'download', sortable: false },
          { text: 'Signer le registre', value: 'final_signature_chef_exploitation', sortable: false}
        ]
      }        
    },
    computed: {
      listRecords(){
        return this.$store.state.liftsStore.listLiftRecords;
      },
      isLoading(){
        return this.$store.state.liftsStore.liftsListIsLoading;
      },
      isSignable (){
        if(this.$store.state.authStore.isResponsableRM){
          return false;
        } else{
          return true;
        }
      },
      liftId(){
        return this.$store.state.liftsStore.selectedLiftCentral.lift_id
      }
    },
    methods: {
        signRecord(value, lift_id, date_usage){
          this.$store.state.liftsStore.liftRecordProps['date'] = date_usage
          this.$store.state.liftsStore.liftRecordProps['lift_id'] = lift_id
          this.$store.dispatch("getLiftRecordStored");
          let updatingObject = {}
          updatingObject['final_signature_chef_exploitation'] = value
          this.$store.dispatch('modifyLiftRecord', updatingObject)
        },
        customSort(items, index, isDesc) {
          items.sort(function(a,b){
            let result = 0
            for(let i = 0; i < index.length; i++){
              let x = b
              let y = a
              if(isDesc[i]) {
                x = a
                y = b
              }
              if(index[i] == 'date') {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                const [dayx, monthx, yearx] = x.date.split('/');
                const [dayy, monthy, yeary] = y.date.split('/');
                result = new Date(+yearx, +monthx - 1, +dayx) - new Date(+yeary, +monthy - 1, +dayy)
              } else {
                result = x[index[i]].localeCompare(y[index[i]])
              }
              if (result != 0) {
                break
              }
            }
            return result;
          });
          return items;
        }
    },
    mounted() {
      this.$store.dispatch("updateListLiftRecords");
    }
  }


</script>